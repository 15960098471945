import React, { useState, useEffect ,useCallback } from "react";
import Axios from 'axios'
import { TabContent, TabPane, Nav, NavItem, FormFeedback, FormText, NavLink, Card, Button, CardTitle, CardText, Row, Col, Input, Label, Form, FormGroup } from 'reactstrap';
import './EditCoachProfile.css'
import Switch from "react-switch";
import CountryStateCity from "./country-state-city"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import Education from "./Education";
import Experince from "./Experince";
import { FiChevronDown } from "react-icons/fi"
import ProfileImg from "../../src/assets/prof-img.jpg"
import { useParams } from 'react-router-dom';
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserImage from '../assets/userImage.jpeg'
import Availability from './availability'
import DeleteaccPop from "./deleteaccpop";
import AddAccPopProps from "./addaccpop";
import { BsBank2 } from "react-icons/bs";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


const initialPersonalDetail = {
    firstName: "",
    lastName: "",
    email: " ",
    address1: "",
    address2: "",
    zipcode: "",
    bio: "",
    country: "",
    state: "",
    city: "",
    country_id: "",
    mbts_phone_number: "",
    mbts_facebook: "",
    mbts_instagram: "",
    mbts_linkedin: "",
    mbts_X: "",
    errors: {
        userFirstNameEmpty: "",
        userLastNameEmpty: "",
        emailEmpty: "",
        address1Empty: "",
        countryEmpty: "",
        stateEmpty: "",
        cityEmpty: "",
        zipcode: "",
        phoneNumberEmpty: "",
        instagramUrlEmpty: "",
        linkedinUrlEmpty: "",
        xUrlEmpty: "",
        facebookUrlEmpty: "",
        zipCodeEmpty: "",
        phoneNumberEmpty: "",
    },

};
const initialEducation = {
    mbts_school: "",
    mbts_degree: "Degree",
    mbts_field_Of_study: "Field of Study",
    mbts_grade: "",
    mbts_startMonth: "Start Month",
    mbts_startYear: "",
    mbts_endMonth: "End Month",
    mbts_endYear: "",
    mbts_description: "",
};
const initialExperience = {
    mbts_isCurrentEmployment: "yes",
    mbts_title: "",
    mbts_employment_type: "Employment Type",
    mbts_company_name: "",
    mbts_location: "",
    mbts_profile_headline: "",
    mbts_industry: "",
    mbts_startMonth: "Start Month",
    mbts_startYear: "",
    mbts_endMonth: "End Month",
    mbts_endYear: "",
    mbts_description: "",
    mbts_totalMonth:"",
    mbts_totalYear:"",
};

const initialBankDetail = {
    mbts_bankName: "",
    mbts_accountType: "",
    mbts_routingNumber: "",
    mbts_accountNumber: "",
    mbts_address1: "",
    mbts_address2: "",
    mbts_primaryAccount: false
}
const EditCoachProfile = (props) => {

    const [checked, setChecked] = useState(false);
    const [countryId, setCountryId] = useState();
    const [image, setImage] = useState();
    const [userid, setUserId] = useState();
    // const [personalDetails , setPersonalDetails] = useState();
    const [personalDetails, setPersonalDetails] = useState(initialPersonalDetail);
    const [educationFormArray, setEducationFormArray] = useState([
        initialEducation,
    ]);
    const [bankDetails, setBankDetails] = useState([initialBankDetail]);


    const [experienceFormArray, setExperienceFormArray] = useState([initialExperience,]);
    const { userId } = useParams();
    // setUserId(userId);
    const [errorMessages, setErrorMessages] = useState({
        firstName: "",
        lastName: "",
        mbts_phone_number: "",
        email: "",
        address1: "",
        address2: "",
        // ... add other fields here
    });
    const {
        firstName,
        lastName,
        errors,
    } = personalDetails;
    const handleChange = (newChecked) => {
        setChecked(newChecked);
    };


    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isPrimaryAccount, setIsPrimaryAccount] = useState(false);
    const [phone, setPhone] = useState();
    const [countryCode, setCountryCode] = useState("");

    // const toggle = (tab) => {
    //     if (activeTab !== tab) setActiveTab(tab);
    //   };
    // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const togglePasswordVisibilityOld = () => {
        setShowPasswordOld(!showPasswordOld);
    };
    const togglePasswordVisibilityNew = () => {
        setShowPasswordNew(!showPasswordNew);
    };

    const togglePasswordVisibilityConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const handleChange5 = (value, country) => {
        let countryCode = country.countryCode;
        setPhone(value);
        //setCountryCode(country.countryCode);
    };

    const handlePrimaryAccountChange = async (e, index) => {
        // Toggle the value when the radio button is clicked
        console.log("161====", index);
        const { name, value } = e.target;
        // Update the error state
        console.log("178===++++", name, value)
        // if (bankDetails.length > 0) {
        const { checked } = e.target;

        // Update the mbts_primaryAccount property based on whether the radio button is checked or not
        let newArray = bankDetails.map((bank, i) => ({
            ...bank,
            mbts_primaryAccount: i === index ? checked : false,
        }));
        // let newArray = [...bankDetails];
        // newArray[index] = { ...newArray[index], [name]: value };
        console.log("112", newArray);
        setBankDetails(newArray);

        let data = {
            userId: userId,
            mbts_bankDetails: newArray
        }

        console.log('470======', data);
        let token = localStorage.getItem("token");
        const addBankDetailsResponse =
            await combinedServices.addBankDetails(data, token);
        if (addBankDetailsResponse.data.code == 200) {
            toast.success("Data Updated Successfully", {
                position: "top-right",
            });

        }


    };
    const handlePassword = (e) => {
        console.log("146=====", e.target);
        let name = e.target.name

        if (name === "oldPassword" && oldPasswordError) {
            setOldPasswordError("");
        }
        if (name === "newPassword" && newPasswordError) {
            setNewPasswordError("");
        }
        if (name === "confirmPassword" && confirmPasswordError) {
            setConfirmPasswordError("");
        }
        if (name == "oldPassword") {
            console.log("153====")
            setOldPassword(e.target.value);
        }

        if (name == "newPassword") {
            console.log("157====")
            setNewPassword(e.target.value)
        }
    };


    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleChange4 = (e) => {

        const { name, value } = e.target;
        // Update the error state
        console.log("178===", name, value)
        if (e.target.id) {
            setCountryId(e.target.id);
        }
        setPersonalDetails({
            ...personalDetails,
            [name]: value,
        });

    };

    const handleChange3 = (newArray) => {
        setExperienceFormArray(newArray);
    };


    const fetchData = async (userid) => {
        try {
            console.log("221=====")
            const response = await Axios.post("/admin/getUserProfileData", { user_id: userId });
            console.log("221=====+++++", response);
            let image_url = response?.data?.data?.mbts_image_url;
            if (image_url) {
                setImage(image_url);
            }

            if (response?.data?.data.mbts_personal_details) {
                if (response?.data?.data.mbts_personal_details.length > 0) {
                    // setPersonalDetails({
                    //     firstName: response?.data?.data.mbts_personal_details[0].mbts_first_name,
                    //     lastName: response.data.data.mbts_personal_details[0].mbts_last_name,
                    //     email: response.data.data.mbts_personal_details[0].mbts_email,
                    //     address1: response.data.data.mbts_personal_details[0].mbts_address1,
                    //     // Add other fields here
                    // });
                    setPersonalDetails({
                        firstName:
                            response?.data?.data.mbts_personal_details[0].mbts_first_name,
                        lastName:
                            response.data.data.mbts_personal_details[0].mbts_last_name,
                        email: response.data.data.mbts_personal_details[0].mbts_email,
                        address1:
                            response.data.data.mbts_personal_details[0].mbts_address1,
                        address2:
                            response.data.data.mbts_personal_details[0].mbts_address2,
                        zipcode: response.data.data.mbts_personal_details[0].mbts_zipcode,
                        bio: response.data.data.mbts_personal_details[0].mbts_bio,
                        country_id: response.data.data.mbts_personal_details[0].mbts_country_id,
                        country: response.data.data.mbts_personal_details[0].mbts_coountry,
                        state: response.data.data.mbts_personal_details[0].mbts_state,
                        city: response.data.data.mbts_personal_details[0].mbts_city,
                        mbts_phone_number: response.data.data.mbts_personal_details[0].mbts_phone_number,
                        mbts_facebook: response.data.data.mbts_personal_details[0].mbts_facebook,
                        mbts_instagram: response.data.data.mbts_personal_details[0].mbts_instagram,
                        mbts_linkedin: response.data.data.mbts_personal_details[0].mbts_linkedin,
                        mbts_X: response.data.data.mbts_personal_details[0].mbts_X

                    });
                    setPhone(response.data.data.mbts_personal_details[0].mbts_phone_number);

                } else {
                    let config = { params: { user_id: userId } };
                    const userDetailsResponse = await Axios.get("/admin/getUserDetails", config);
                    setPersonalDetails({
                        firstName: userDetailsResponse?.data?.data.mbts_firstName,
                        lastName: userDetailsResponse.data.data.mbts_lastName,
                        email: userDetailsResponse.data.data.mbts_email,
                        address1:
                            "",
                        address2:
                            "",
                        zipcode: "",
                        bio: "",
                        country_id: "",
                        country: "",
                        state: "",
                        city: "",
                        mbts_phone_number: "",
                        mbts_facebook: "",
                        mbts_instagram: "",
                        mbts_linkedin: "",
                        mbts_X: "",
                    });
                }
            } else {
                let config = { params: { user_id: userId } };
                const userDetailsResponse = await Axios.get("/admin/getUserDetails", config);
                setPersonalDetails({
                    firstName: userDetailsResponse?.data?.data.mbts_firstName,
                    lastName: userDetailsResponse.data.data.mbts_lastName,
                    email: userDetailsResponse.data.data.mbts_email,
                    address1:
                        "",
                    address2:
                        "",
                    zipcode: "",
                    bio: "",
                    country_id: "",
                    country: "",
                    state: "",
                    city: "",
                    mbts_phone_number: "",
                    mbts_facebook: "",
                    mbts_instagram: "",
                    mbts_linkedin: "",
                    mbts_X: "",
                });
            }
            if (response?.data?.data?.mbts_bankDetails.length > 0) {
                setBankDetails(response.data.data.mbts_bankDetails);
            }
            if (response.data.data.mbts_education_details.length > 0) {
                setEducationFormArray(response.data.data.mbts_education_details);
            }

            if (response.data.data.mbts_experience.length > 0) {
                setExperienceFormArray(response.data.data.mbts_experience);
            }
        } catch (error) {
            console.error("Error in getUserDetails request:", error);
        }
    };

    useEffect(() => {
        console.log("123=====================")
        // Call fetchData inside useEffect
        fetchData(userId);
    }, [userId]);

    // const validatePersonalDetails = () => {
    //     console.log("240===", personalDetails)
    //     let isValid = true;
    //     let errorMessagesCopy = { ...errorMessages };

    //     // Validate first name
    //     if (!personalDetails.firstName || !personalDetails.firstName.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.firstName = "First Name is required";
    //     } else {
    //         errorMessagesCopy.firstName = ""; // Clear the error if valid
    //     }
    //     if (!personalDetails.firstName || !personalDetails.firstName.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.lastName = "Last Name is required";
    //     } else {
    //         errorMessagesCopy.lastName = ""; // Clear the error if valid
    //     }
    //     if (!personalDetails.mbts_phone_number || !personalDetails.mbts_phone_number.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.mbts_phone_number = "Phone Number is required";
    //     } else {
    //         errorMessagesCopy.mbts_phone_number = ""; // Clear the error if valid
    //     }
    //     if (!personalDetails.email || !personalDetails.email.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.email = "Phone Number is required";
    //     } else {
    //         errorMessagesCopy.email = ""; // Clear the error if valid
    //     }

    //     // Add other validations for personal details fields here
    //     console.log("240===", isValid, errorMessagesCopy)
    //     return { isValid, errorMessages: errorMessagesCopy };
    // };

    //handling vailidation 
    const handleValidation = () => {
        console.log('391=====', personalDetails);
        let formIsValid = true;
        const newErrors = {
            userFirstNameEmpty: "",
            userLastNameEmpty: "",
            address1Empty: ""

        };
        console.log('399=====');
        if (!/^[A-Za-z]+$/.test(personalDetails.firstName)) {
            newErrors.userFirstNameEmpty = "Please enter first name.";
            formIsValid = false;
        }
        console.log('403=====');
        if (!/^[A-Za-z]+$/.test(personalDetails.lastName)) {
            newErrors.userLastNameEmpty = "Please enter last name.";
            formIsValid = false;
        }
        console.log('408=====');
        if (!personalDetails.email) {
            newErrors.emailEmpty = "Please enter email ID.";
            formIsValid = false;
        }
        else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(personalDetails.email)) {
            newErrors.emailEmpty = "Email format is not valid";
            formIsValid = false;
        }
        console.log('417=====');
        if (!personalDetails.address1) {
            console.log('419==========', newErrors)
            newErrors.address1Empty = "Please enter address.";
            formIsValid = false;
            console.log('423==========', newErrors)
        }
        if (!phone) {
            newErrors.phoneNumberEmpty = "Please enter phone number.";
            formIsValid = false;
        }
        if (!personalDetails.zipcode) {
            console.log("414=======",personalDetails.zipcode);
            newErrors.zipCodeEmpty = "Please enter zipCode.";
            formIsValid = false;
        }
        console.log('426=====');
        if (!personalDetails.country) {
            newErrors.countryEmpty = "Please enter country.";
            formIsValid = false;
        }
        console.log('431=====');
        if (!personalDetails.city) {
            newErrors.cityEmpty = "Please enter city.";
            formIsValid = false;
        }
        console.log('436=====');
        if (!personalDetails.state) {
            newErrors.stateEmpty = "Please enter state.";
            formIsValid = false;
        }
        console.log('441=====', personalDetails);
        if (personalDetails.mbts_instagram.trim() !== "" && !/^(ftp|http|https):\/\/[^ "]+$/.test(personalDetails.mbts_instagram)) {
            console.log('441=====', newErrors);
            newErrors.instagramUrlEmpty = "Url format is not valid";
            formIsValid = false;
        }
        console.log('446=====');
        if (personalDetails.mbts_facebook.trim() !== "" && !/^(ftp|http|https):\/\/[^ "]+$/.test(personalDetails.mbts_facebook)) {
            newErrors.facebookUrlEmpty = "URL format is not valid";
            formIsValid = false;
        }
        console.log('451=====');
        if (personalDetails.mbts_X.trim() !== "" && !/^(ftp|http|https):\/\/[^ "]+$/.test(personalDetails.mbts_X)) {
            newErrors.xUrlEmpty = "URL format is not valid";
            formIsValid = false;
        }
        console.log('456=====');
        if (personalDetails.mbts_linkedin.trim() !== "" && !/^(ftp|http|https):\/\/[^ "]+$/.test(personalDetails.mbts_linkedin)) {
            newErrors.linkedinUrlEmpty = "URL format is not valid";
            formIsValid = false;
        }

        console.log('464=======', newErrors);

        console.log("275==++++");
        setPersonalDetails({
            ...personalDetails,
            errors: newErrors,
        });
        console.log('464=======', newErrors);

        return formIsValid;
    }
    const handleUpdate = async function (id) {
        try {
            console.log("285====")
            const formIsValid = handleValidation();
            console.log("275===!!!!", formIsValid, errors);
            // let isValid = true;
            // let errorMessagesCopy = { ...errorMessages }; // Create a copy of the errorMessages state
            // if (activeTab === "1") {
            //     // Validate personal details
            //     const personalDetailsValidation = validatePersonalDetails();
            //     console.log("268====", personalDetailsValidation)
            //     isValid = personalDetailsValidation.isValid;
            //     errorMessagesCopy = { ...errorMessagesCopy, ...personalDetailsValidation.errorMessages };
            // }

            // if (!isValid) {
            //     // Set the updated error messages state
            //     setErrorMessages(errorMessagesCopy);
            //     // Form is not valid, you can handle this as needed
            //     return;
            // }
            if (activeTab == "3") {
                let data = {
                    mbts_experience: experienceFormArray

                };
                let token = localStorage.getItem("token");
                const exeperienceDetailsResponse =
                    await combinedServices.createExperienceDetails(data, token);
                if (exeperienceDetailsResponse.data.code == 200) {
                    toast.success("Data Updated Successfully", {
                        position: "top-right",
                    });

                }
            }
            if (activeTab == "2") {

                let data = {
                    mbts_education_details: educationFormArray
                };
                let token = localStorage.getItem("token");
                const educationDetailsResponse =
                    await combinedServices.createEducationDetails(data, token);
                if (educationDetailsResponse.data.code == 200) {

                    toast.success("Data Updated Successfully", {
                        position: "top-right",
                    });

                }
            }
            if (activeTab == "1") {
                // isValid = validatePersonalDetails();
                console.log("288=====", personalDetails);
                if (formIsValid) {
                    // Your existing logic for updating personal details...

                    const data = {
                        userId: userId,
                        mbts_personal_details: [
                            {
                                mbts_first_name: personalDetails.firstName,
                                mbts_last_name: personalDetails.lastName,
                                mbts_phone_number: personalDetails.phone,
                                mbts_email: personalDetails.email,
                                mbts_address1: personalDetails.address1,
                                mbts_address2: personalDetails.address2,
                                mbts_state: personalDetails.state,
                                mbts_city: personalDetails.city,
                                mbts_countryCode: personalDetails.countryCode,
                                mbts_coountry: personalDetails.country,
                                mbts_state: personalDetails.state,
                                mbts_city: personalDetails.city,
                                mbts_zipcode: personalDetails.zipcode,
                                mbts_bio: personalDetails.bio,
                                mbts_country_id: countryId,
                                mbts_phone_number: phone,
                                mbts_facebook: personalDetails.mbts_facebook,
                                mbts_X: personalDetails.mbts_X,
                                mbts_linkedin: personalDetails.mbts_linkedin,
                                mbts_instagram: personalDetails.mbts_instagram
                            },
                        ],
                    };
                    console.log('470======', data);
                    let token = localStorage.getItem("token");
                    const personalDetailsResponse =
                        await combinedServices.createUserPersonalInfomation(data, token);
                    if (personalDetailsResponse.data.code == 200) {
                        toast.success("Data Updated Successfully", {
                            position: "top-right",
                        });

                    }
                }
            }

        } catch (err) {
            return err;
        }
    };
    const handleSuccess = useCallback(() => {
        fetchData(userId);
      }, [userId]); // Only re-create the function if userId changes
    
    const lastIndex = bankDetails.length;
    console.log("390==================", bankDetails);

    return (
        <>
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div className="container">
                            <div className="student_Title mb-3" >
                                <h1>Edit Coach Profile </h1>
                            </div>
                            <Nav tabs className="profileTab">
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}>
                                        Personal Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}>
                                        Educational Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggle('3'); }}>
                                        Experience Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '4' })}
                                        onClick={() => { toggle('4'); }}>
                                        Cost &amp; Availability
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '5' })}
                                        onClick={() => { toggle('5'); }}>
                                        Payment Settings
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '6' })}
                                        onClick={() => { toggle('6'); }}>
                                        Change Password
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="editProfOuter">
                                        <div className="profEdit_Outer">
                                            <div className="profileImage">
                                                <i>
                                                    {image ? (
                                                        <img src={image} alt="User Profile" />
                                                    ) : (
                                                        <img src={UserImage} alt="Default Profile" />
                                                    )}
                                                </i>
                                            </div>
                                            <div className="profEfit_Right">
                                                <Form>
                                                    <div className="editProfFrmBx">
                                                        <div className="editFrmOuter">
                                                            <div className="frmBx">
                                                                {/* <FormGroup className="form_grp errorCont">
                                                                    <Input type="Name" className="error" name="firstName" value={personalDetails.firstName} onChange={handleChange4} id="exampleEmail" placeholder="First Name" />
                                                                    <small className='error_msg'>error message</small>
                                                                </FormGroup> */}
                                                                <FormGroup className={`form_grp ${errors?.userFirstNameEmpty ? 'errorCont' : ''}`}>
                                                                    <Input
                                                                        type="Name"
                                                                        className="error"
                                                                        name="firstName"
                                                                        value={personalDetails.firstName}
                                                                        onChange={handleChange4}
                                                                        id="exampleEmail"
                                                                        placeholder="First Name"
                                                                    />
                                                                    <small className="error_msg">{errors?.userFirstNameEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className={`frmBx form_grp ${errors?.userLastNameEmpty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="Name" className="error" name="lastName" value={personalDetails.lastName} onChange={handleChange4} id="exampleEmail" placeholder="Last Name" />
                                                                    <small className="error_msg">{errors?.userLastNameEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className={`frmBx form_grp frmnumb ${errors?.phoneNumberEmpty ? 'errorCont' : ''}`}>
                                                                {/* <FormGroup>
                                                                    <Input type="Name" className="error" name="mbts_phone_number" value={personalDetails.mbts_phone_number} onChange={handleChange4} id="exampleEmail" placeholder="Phone Number" />
                                                                    <small className="error_msg">{errors?.phoneNumberEmpty}</small>
                                                                </FormGroup> */}
                                                                <PhoneInput
                                                                    country={countryCode}
                                                                    value={phone}
                                                                    onChange={handleChange5}
                                                                />
                                                                <small className="error_msg">{errors?.phoneNumberEmpty}</small>
                                                            </div>
                                                        </div>
                                                        <div className="editFrmOuter">
                                                            <div className={`frmBx form_grp ${errors?.emailEmpty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="email" className="error" name="email" readOnly onChange={handleChange4} value={personalDetails.email} id="exampleEmail" placeholder="Email" />
                                                                    <small className="error_msg">{errors?.emailEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className={`frmBx form_grp ${errors?.address1Empty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="Name" className="error" name="address1" onChange={handleChange4} value={personalDetails.address1} id="exampleEmail" placeholder="Address1" />
                                                                    <small className="error_msg">{errors?.address1Empty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="frmBx">
                                                                <FormGroup>
                                                                    <Input type="Name" name="address2" onChange={handleChange4} value={personalDetails.address2} id="exampleEmail" placeholder="Address2" />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <CountryStateCity countryEmpty={errors?.countryEmpty} stateEmpty={errors?.stateEmpty} cityEmpty={errors?.cityEmpty} country={personalDetails.country} country_id={personalDetails.country_id} city={personalDetails.city} state={personalDetails.state} handleChange4={handleChange4} />

                                                        </div>
                                                        <div className="editFrmOuter">
                                                            <div className={`frmBx ${errors?.zipCodeEmpty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="Name" className={`form_ctrl ${errors?.zipCodeEmpty ? 'error' : ''}`} name="zipcode" value={personalDetails.zipcode} onChange={handleChange4} id="exampleEmail" placeholder="Zipcode" />
                                                                    <small className="error_msg">{errors?.zipCodeEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="frmBx">
                                                                <FormGroup>
                                                                    <Input type="Name" name="bio" value={personalDetails.bio} onChange={handleChange4} id="exampleEmail" placeholder="Personal Bio (Married with 3 kids and I enjoy biking, fishing and jogging.)" />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="frmBx">&nbsp;</div>
                                                        </div>
                                                        <h2>Social Links</h2>
                                                        <ul className="socialLink">
                                                            <li>
                                                                <div className={`form_grp ${errors?.facebookUrlEmpty ? 'errorCont' : ''}`}>
                                                                    <input
                                                                        type="text"
                                                                        name="mbts_facebook"
                                                                        className={`form_ctrl ${errors?.facebookUrlEmpty ? 'error' : ''}`}
                                                                        onChange={handleChange4}
                                                                        value={personalDetails.mbts_facebook}
                                                                        placeholder="Facebook URL"
                                                                    />
                                                                    <small className="error_msg">{errors?.facebookUrlEmpty}</small>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className={`form_grp ${errors?.instagramUrlEmpty ? 'errorCont' : ''}`}>
                                                                    <input
                                                                        type="text"
                                                                        name="mbts_instagram"
                                                                        // className="form_ctrl"
                                                                        className={`form_ctrl ${errors?.instagramUrlEmpty ? 'error' : ''}`}
                                                                        onChange={handleChange4}
                                                                        value={personalDetails.mbts_instagram}
                                                                        placeholder="Instagram URL"
                                                                    />
                                                                    <small className="error_msg">{errors?.instagramUrlEmpty}</small>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className={`form_grp ${errors?.xUrlEmpty ? 'errorCont' : ''}`}>
                                                                    <input
                                                                        type="text"
                                                                        name="mbts_X"
                                                                        className={`form_ctrl ${errors?.xUrlEmpty ? 'error' : ''}`}
                                                                        onChange={handleChange4}
                                                                        value={personalDetails.mbts_X}
                                                                        placeholder="X (Former Twitter) URL"
                                                                    />
                                                                    <small className="error_msg">{errors?.xUrlEmpty}</small>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className={`form_grp ${errors?.linkedinUrlEmpty ? 'errorCont' : ''}`}>
                                                                    <input
                                                                        type="text"
                                                                        name="mbts_linkedin"
                                                                        className={`form_ctrl ${errors?.linkedinUrlEmpty ? 'error' : ''}`}
                                                                        onChange={handleChange4}
                                                                        value={personalDetails.mbts_linkedin}
                                                                        placeholder="LinkedIn URL"
                                                                    />
                                                                    <small className="error_msg">{errors?.linkedinUrlEmpty}</small>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Form>
                                                <div className="BtnsOuter">
                                                    <button className="cancelBtn" onClick={fetchData}>Cancel</button>
                                                    <button className="submitBtn" onClick={handleUpdate}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Education educationFormArray={educationFormArray} userId={userId} />
                                    {/* <div className="BtnsOuter">
                                        <button className="cancelBtn">Cancel</button>
                                        <button className="submitBtn">Submit</button>
                                    </div> */}
                                </TabPane>
                                <TabPane tabId="3">
                                    <Experince experienceFormArray={experienceFormArray} userId={userId} />

                                    {/* <div className="BtnsOuter">
                                        <button className="cancelBtn">Cancel</button>
                                        <button className="submitBtn" onClick={handleChange3}>Submit</button>
                                    </div> */}
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="edumain">
                                        <div className="profileBx">
                                            <div className="availabilityOuter">
                                                <h3>Availability</h3>
                                                <Availability />
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="edumain">
                                        <div className="profileBx">
                                            <div className="paymentSettingOuter">
                                                <h3>Payment Settings</h3>
                                                {bankDetails.length > 0 && bankDetails?.map((bank, index) => (
                                                    bank.mbts_bankName !== "" && bank.mbts_accountNumber !== "" && (
                                                        <div className="paymentbankBx" key={index}>
                                                            <div className="bankLame">
                                                                <i>
                                                                    <BsBank2 />
                                                                </i>
                                                                <strong>{bank.mbts_bankName}</strong>
                                                                <span>A/c No. {bank.mbts_accountNumber}</span>
                                                            </div>
                                                            <div className="acctype">
                                                                <div className="actypInn">
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input
                                                                                type="radio"
                                                                                name="mbts_primaryAccount"
                                                                                checked={bank.mbts_primaryAccount}
                                                                                onChange={(e) => handlePrimaryAccountChange(e, index)}
                                                                            />
                                                                            {' '}
                                                                            Primary Account
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                                <DeleteaccPop index={index} bankDetails={bankDetails} userId={userId} onSuccess={handleSuccess} />
                                                            </div>
                                                        </div>
                                                    )
                                                ))}

                                                <div className="paymentbankBx">
                                                    <div className="bankLame">
                                                        <i><BsBank2 /></i>
                                                        <strong>New Bank Account</strong>
                                                    </div>
                                                    <div className="acctype">
                                                        <AddAccPopProps userId={userId} bankDetails={bankDetails} lastIndex={lastIndex} onSuccess={handleSuccess} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                {/* Start Tab section 6 */}
                                {/* <TabPane tabId="6">
                                    <div className="edumain">
                                        <div className="profileBx">
                                            <h2>Change Password</h2>
                                            <div className="pass_box passDash">
                                                <div className={`form_grp ${oldPasswordError ? 'error' : ''}`}>
                                                    <input
                                                        type={showPasswordOld ? "text" : "password"}
                                                        name="oldPassword"
                                                        value={oldPassword}
                                                        onChange={handlePassword}
                                                        
                                                        className={`form_ctrl ${oldPasswordError ? 'error' : ''}`}
                                                        placeholder="Old Password"
                                                    />
                                                    <span
                                                        onClick={togglePasswordVisibilityOld}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {" "}
                                                        <FontAwesomeIcon
                                                            icon={showPasswordOld ? faEye : faEyeSlash}
                                                        />
                                                    </span>

                                                    <span className={`error_msg  ${oldPasswordError ? 'show' : 'hide'}`}>{oldPasswordError}</span>
                                                </div>


                                                <div className={`form_grp ${newPasswordError ? 'error' : ''}`}>
                                                    <input
                                                        type={showPasswordNew ? "text" : "password"}
                                                        name="newPassword"
                                                        value={newPassword}
                                                        onChange={handlePassword}
                                                      
                                                        className={`form_ctrl ${newPasswordError ? 'error' : ''}`}
                                                        placeholder="New Password"
                                                    />
                                                    <span
                                                        onClick={togglePasswordVisibilityNew}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                      
                                                        <FontAwesomeIcon
                                                            icon={showPasswordNew ? faEye : faEyeSlash}
                                                        />
                                                    </span>
                                                    <span className={`error_msg  ${newPasswordError ? 'show' : 'hide'}`}>{newPasswordError}</span>

                                                </div>
                                                <div className={`form_grp ${confirmPasswordError ? 'error' : ''}`}>
                                                    <input
                                                        type={showPasswordConfirm ? "text" : "password"}
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                       
                                                        onChange={handlePassword}
                                                        className={`form_ctrl ${confirmPasswordError ? 'error' : ''}`}
                                                       
                                                        placeholder="Confirm Password"
                                                    />
                                                    <span
                                                        onClick={togglePasswordVisibilityConfirm}
                                                        style={{ cursor: "pointer" }}
                                                    >

                                                       {" "}
                                                        <FontAwesomeIcon
                                                            icon={showPasswordConfirm ? faEye : faEyeSlash}
                                                        />
                                                    </span>
                                                    <span className={`error_msg  ${confirmPasswordError ? 'show' : 'hide'}`}>{confirmPasswordError}</span>

                                              
                                                </div>
                                                <div className="passList">
                                                    <strong>Password must be:</strong>
                                                    <ul>
                                                        <li>• Between 8 and 20 characters</li>
                                                        <li>• An uppercase character</li>
                                                        <li>• A lowercase character</li>
                                                        <li>• A number</li>
                                                        <li>• A special character</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane> */}
                            </TabContent>



                        </div>

                    </div>

                </div>
            </div >




        </>
    )
}
export default EditCoachProfile;
