
import React, { useState , useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import close from "../../assets/close.svg";
import success from "../../assets/success.svg";
import { Link } from 'react-router-dom';
import './SuccessActivationUser.css'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';



const SuccessActivationUser = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  //function to open Modal....
  const toggle = () => setModal(!modal);
  useEffect(() => {
    // Get the URL search string (e.g., "?token=...")
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const id = searchParams.get("id");
    console.log("Token from URL:", token);
    let value
    if(token){
     value = {
        mbts_verification_token: token
    }
}else{
     value = {
        id: id
    }
}
    Axios.post("/admin/verifyEmail", value)
        .then((response) => {
            console.log("verify email response response", response);
            if (response.data.code == 200) {
                // setAuthenticationToken(response.data.data);
                localStorage.setItem('authenticationToken', response.data.data);
            }
            else if (response.data.code == 500) {
                navigate("/error");
            }
        })
        .catch((error) => {
            console.log(error)
        })
}, []);

  return (
    <div className='congOuter'>
      {/* <Button
        className="popFBtn  wd-100"
        type="button"
        onClick={toggle}
      >
        Register
      </Button>
      <Modal isOpen={modal} toggle={toggle} className='mbtsModCont'>
        <ModalBody> */}
          {/* button to close popup */}         
          {/* <Link to="/">  */}
          {/* <Button
            className="popCloseBtn"
            type="button"
            onClick={toggle}
          >
            <img src={close} alt="close button" />
          </Button> */}
          {/* </Link>  */}
          <div className="popIconWrp">
            <img src={success} alt="success icon" />
          </div>
          {/* <h2 className="pop_hd">Congratulations</h2> */}
          <p className="pop_text">
          Coach Account Successfully Activated.      
          </p>

          <div className="popFormBtn">
            {/* <button className="popFBtn" onClick={toggle}>
              
            </button> */}
            {/* <Link exact to="/" className="popFBtn">Back to Login</Link> */}
          </div>
        {/* </ModalBody>
      </Modal> */}
    </div>
  );
};

export default SuccessActivationUser;
