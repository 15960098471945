import React, { useState, useEffect } from "react";
import Axios from 'axios'
import ReactPaginate from 'react-paginate';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';
import close from "../assets/close.svg";
import './NewStudent.css'
import Switch from '@mui/material/Switch'
import { BiEdit } from "react-icons/bi"
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";

const NewStudent = (props) => {
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState('0')
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [dataPerPage, setDataPerPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [userList, setUserList] = useState([]);

    const {
        buttonLabel,
        className
    } = props;
    const [modal, setModal] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [keyboard, setKeyboard] = useState(true);
    const [pageCount, setPageCount] = useState();
    const [loader , setLoader] = useState(true);
    
    const navigateToEditProfile = (userId) => {
        // Use the `navigation` object to navigate to the edit-profile route with the itemId parameter
        navigate(`/dashboard/edit-profile/${userId}`);
    };
    const toggle = () => setModal(!modal);
    const changeBackdrop = e => {
        let value = e.target.value;
        if (value !== 'static') {
            value = JSON.parse(value);
        }
        setBackdrop(value);
    }

    const [isActive, setIsActive] = useState();
    const toggleSwitch = (id , status) => {    
             let mbts_isUserActive=!status
        let token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // You can add other headers as needed
        };
        let data= {
            "mbts_isUserActive":mbts_isUserActive,
            "_id":id
        }
        Axios.post("/admin/updateUserStatus",data , {headers})
        .then((response) => {
            console.log("User Status Response",response);
            if(response.data.code == 200){
                setIsActive(response.data.data.mbts_isUserActive);
                getStudentList(page);
                const message = "Status Updatede Successfully";
                toast.success(message, {
                    position: 'top-right',
                });
            }          
         
        })
        .catch((error) => {

        })
    };

    const changeKeyboard = e => {
        setKeyboard(e.currentTarget.checked);
    }
    useEffect(() => {
        console.log("82===============")

        getStudentList(page);

    }, [])

    const getStudentList = (page ,search) => {
        let data= {
            page:page,
            limit:limit,
            search:search
        }
        setLoadingPage(true)      
        Axios.post("/admin/allUserList",data)
            .then((response) => {
                setLoader(false)
                console.log("User data Response",response);
                setLoadingPage(false)
                const itemsPerPage = 10; // Number of items to display per page
                let totalCount = response.data.data.totalCount ;
                const pageCounts = Math.ceil(totalCount / itemsPerPage);
                setPageCount(pageCounts);             
                let userLists = response.data.data.userList
                setUserList(userLists);            
                setTotalCount(response.data.data.totalCount);
             
            })
            .catch((error) => {

            })
    }
    const handleChangeSearch = (event) =>{
      
        setSearch(event.target.value);
        getStudentList(page ,event.target.value);
    }
    const handlePageClick = (event) => { 
        let value=event.selected + 1;    
        setPage(value);
        getStudentList(value);
    };
    return (
        <>
               {loader && (
        <>
          <div className="loaderCont">
            <div className="d-flex justify-content-center align-items-center h-100">
              <ReactLoading type="bars" color="#32B588" />
            </div>
          </div>
        </>
      )}
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div className="container">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', fontSize: '10px', marginBottom: '20px' }}>
                                <div className="student_Title" >
                                    <h1> Total Users : {totalCount}</h1>
                                </div>
                                <div className="">
                                    <div className="input-group">
                                        <div className="form-outline">
                                            <input type="search" placeholder="Search" id="form1" className="form-control searchBar" name="search" value={search} onChange={handleChangeSearch} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tableWrapper">
                            <table className="table table-bordered" cellPadding="0" cellSpacing="0" border="0">
                                    <thead>
                                    <tr>
                                            <th width="60" scope="col">S.No.</th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">last Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Registered</th>
                                            <th scope="col">Email Verified</th>
                                            <th scope="col">Status</th>
                                            <th width="80" scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                        {userList?.map((item, index) => (
                                            <tr key={item.id}>
                                               <td>{(page - 1) * limit + index + 1}</td>
                                                <td>{item.mbts_firstName} </td>
                                                <td>{item.mbts_lastName}</td>
                                                <td>{item.mbts_email}</td>
                                                <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                                <td>{item.mbts_isEmailVerified ? 'Verified' : 'Pending'}</td>
                                                <td>
                                                    <Switch
                                                        checked={item.mbts_isUserActive}
                                                        onChange={() => toggleSwitch(item._id , item.mbts_isUserActive)} // Assuming you need to pass an identifier */}
                                                     />
                                                </td>
                                                <td>
                                                    <button className="editButton"onClick={() => navigateToEditProfile(item._id)}>
                                                        <BiEdit />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>

                            <div className="list-pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    containerClassName="custom-pagination" 
                                />
                            </div>

                        </div>

                    </div>

                </div>
            </div >



            {/* Modal for Edit data */}
            <Modal isOpen={modal} toggle={toggle} backdrop={backdrop} keyboard={keyboard} className='mbtsModCont editData'>

                <ModalBody>
                    <Button
                        className="popCloseBtn"
                        type="button"
                        onClick={toggle}                    >
                        <img src={close} alt="close button" />
                    </Button>
                    <div className="edit_dInner">
                        <h1 className="pop_hd">Edit data</h1>
                        {/* <p className="pop_text">....</p> */}
                        <form>
                            <div className="form-group form_grp error">
                                <input type="text" className="form-control form_ctrl error" name="first Name" id="" placeholder="First Name" value="" />
                                <small className="error_msg">Please enter the first name</small>
                            </div>
                            <div className="form-group form_grp">
                                <input type="text" className="form-control form_ctrl" name="last Name" id="" placeholder="Last Name" value="" />
                                <small className="error_msg"></small>
                            </div>
                            <div className="form-group form_grp">
                                <input type="email" className="form-control form_ctrl" name="email" id="" placeholder="Email Address" value="" />
                                <small className="error_msg"></small>
                            </div>
                            <div className="form-group form_grp">
                                <input type="text" className="form-control form_ctrl" name="Phone No." id="" placeholder="Phone" value="" />
                                <small className="error_msg"></small>
                            </div>
                            <button type="submit" className="btn btn-primary loginButtons popFBtn  wd-100">Submit</button>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

          



        </>
    )
}
export default NewStudent;
