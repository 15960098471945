import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, Nav, TabPane, div, TabContent, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';
import YearDropdown from "./YearDropdown";
import EndYearDropdown from './EndYearDropdown'
import { FiChevronDown } from "react-icons/fi";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Warning from '../assets/warning.svg'
import close from "../assets/close.svg";

const initialExperience = {
  mbts_isCurrentEmployment: "yes",
  mbts_title: "",
  mbts_employment_type: "Employment Type",
  mbts_company_name: "",
  mbts_location: "",
  mbts_profile_headline: "",
  mbts_industry: "",
  mbts_startMonth: "Start Month",
  mbts_startYear: "",
  mbts_endMonth: "End Month",
  mbts_endYear: "",
  mbts_description: "",
  errors: {
    titleEmpty: "",
    employmentTypeEmpty: "",
    companyNameEmpty: "",
    locationEmpty: "",
    profileHeadlineEmpty: "",
    industryEmpty: "",
    startMonthEmpty: "",
    startYearEmpty: "",
    endMonthEmpty: "",
    endYearEmpty: "",
    mbts_totalMonthEmpty:"",
    mbts_totalYearEmpty:""
  }
};
function Experince(props) {
  const [customDiv, setCustomDiv] = useState(['div1']);
  const [experienceFormArray, setExperienceFormArray] = useState([initialExperience,]);
  const [confiramtionpopup, setConfirmationPopup] = useState(false)
  const [backdrop, setBackdrop] = useState(false);
  const [keyboard, setKeyboard] = useState(true);
  const [startYear, setStartYear] = useState();
  const [index, setIndex] = useState();
  console.log('10=====', props.experienceFormArray, props.userId);
  const addNewRow = () => {
    // setCustomDiv([...customDiv, 'newDiv']);
    const formIsValid = handleValidation();
    if (formIsValid) {
    setExperienceFormArray([...experienceFormArray, initialExperience]);
    }
    else{
      toast.error("Please Fill Required Field", {
        position: "top-right",
      });
    }


  };
  const toggleConfimation = (index) => {
    setConfirmationPopup(!confiramtionpopup);
    setIndex(index)

  }

  const toogleCancel = () => {
    setConfirmationPopup(!confiramtionpopup);
  }
  const removeRow = async(id) => {
    console.log('43=========', id);
    const updatedData = experienceFormArray.filter((item, index) => index !== id);
    console.log("45==========", updatedData);
    setExperienceFormArray(updatedData);
    setConfirmationPopup(!confiramtionpopup);

    let data = {
      userId: props.userId,
      mbts_experience: updatedData

  };
  console.log("53----",data);
  let token = localStorage.getItem("token");
  const exeperienceDetailsResponse =
      await combinedServices.createExperienceDetails(data, token);
  if (exeperienceDetailsResponse.data.code == 200) {
      toast.success("Data Updated Successfully", {
          position: "top-right",
      });
  }


  };
  const handleChangeCheckBox = (e, index, fieldName) => {
    const { value } = e.target;
    if (experienceFormArray.length > 0) {
      let newArray = [...experienceFormArray];
      newArray[index] = { ...newArray[index], [fieldName]: value };
      setExperienceFormArray(newArray);
    }
  };
  const fetchData = ()=>{
    try{
      setExperienceFormArray(props.experienceFormArray);
    }catch(err){
      return err
    }
  }
  useEffect(() => {
    setExperienceFormArray(props.experienceFormArray);


  }, [props]);

  const handleValidation = () => {
    let formIsValid = true;
    const newErrors = {

    };

    console.log("95===", experienceFormArray)
    for (let i = 0; i < experienceFormArray.length; i++) {
      console.log("97===", experienceFormArray[i]?.mbts_school, i)
      console.log("98=====", experienceFormArray[i].mbts_school, i);
      if (experienceFormArray[i].mbts_title.length == 0) {
        console.log("100====")
        newErrors.titleEmpty = "Please enter title name.";
        formIsValid = false;
      }
      if (experienceFormArray[i]?.mbts_title.length == 0) {
        console.log("100====")
        newErrors.titleEmpty = "Please enter title name.";
        formIsValid = false;
      }
      if (experienceFormArray[i]?.mbts_employment_type == "Employment Type") {
        newErrors.employmentTypeEmpty = "Please select employement name.";
        formIsValid = false;
      }

      if (experienceFormArray[i]?.mbts_company_name?.length == 0) {
        console.log("109===")
        newErrors.companyNameEmpty = "Please enter company name.";
        formIsValid = false;
      }
      if (experienceFormArray[i]?.mbts_location?.length == 0) {
        newErrors.locationEmpty = "Please enter location .";
        formIsValid = false;
      }
      if (experienceFormArray[i]?.mbts_profile_headline?.length == 0) {
        newErrors.profileHeadlineEmpty = "Please enter Profile headline.";
        formIsValid = false;
      }
      if (experienceFormArray[i]?.mbts_industry?.length == 0) {
        newErrors.industryEmpty = "Please select industry name.";
        formIsValid = false;
      }

      if (experienceFormArray[i]?.mbts_totalYear?.length == 0) {
        newErrors.mbts_totalYearEmpty = "Please enter total year experience .";
        formIsValid = false;     
      }
      
      if (experienceFormArray[i]?.mbts_totalMonth?.length == 0) {
        newErrors.mbts_totalMonthEmpty = "Please enter total month experience .";
        formIsValid = false;      
      }
      // if (experienceFormArray[i].mbts_startMonth == "Start Month") {
      //   newErrors.startMonthEmpty = "Please enter start year .";
      //   formIsValid = false;
      // }
      // if (experienceFormArray[i].mbts_endYear.length == 0) {
      //   newErrors.endYearEmpty = "Please enter end year .";
      //   formIsValid = false;
      // }
      // if (experienceFormArray[i].mbts_endMonth == "End Month") {
      //   newErrors.endMonthEmpty = "Please enter end year .";
      //   formIsValid = false;
      // }
      // if (experienceFormArray[i].mbts_startYear.length == 0) {
      //   newErrors.startYearEmpty = "Please enter end year .";
      //   formIsValid = false;
      // }




      console.log("275==++++", newErrors, i);

      // Set errors for the specific index
      // setEducationFormArray(prevState => {
      //     const newArray = [...prevState];
      //     newArray[i] = {
      //         ...newArray[i],
      //         errors: newErrors
      //     };
      //     return newArray;
      // });
      if (experienceFormArray.length > 0) {
        let newArray = [...experienceFormArray];
        newArray[i] = { ...newArray[i], errors: newErrors };
        console.log("112", newArray);
        setExperienceFormArray(newArray);
      }

    }



    return formIsValid;
  }
  const handleChange3 = (e, index) => {
    // console.log("42====",e.target , index);
    const { name, value } = e.target;
    console.log("42====", e.target, index, name, value);
    if (experienceFormArray.length > 0) {
      let newArray = [...experienceFormArray];
      newArray[index] = { ...newArray[index], [name]: value };

      setExperienceFormArray(newArray);
    }

  };
  const handleUpdate = async () => {
    console.log("41===", experienceFormArray);
    const formIsValid = handleValidation();
    console.log("179=====", formIsValid);
    if (formIsValid) {
      let data = {
        userId: props.userId,
        mbts_experience: experienceFormArray

      };
      let token = localStorage.getItem("token");
      const exeperienceDetailsResponse =
        await combinedServices.createExperienceDetails(data, token);
      if (exeperienceDetailsResponse.data.code == 200) {
        toast.success("Data Updated Successfully", {
          position: "top-right",
        });

      }
    }
  }
  console.log("41===", experienceFormArray);
  // const isYesPresent = experienceFormArray.some((item) => item.mbts_isCurrentEmployment === "yes");
  return (
    <>
      <div className='edumain'>
        {experienceFormArray.map((item, index) => (

          <div className="expense-block" key={item} id={`expense-block-${index}`} data-block={index}>
            <Form>
              <div className="currentWorking">
                <strong>Is this your current employment?</strong>
                <span>
                  <Label check>
                    <Input
                      type="radio"
                      name={`mbts_isCurrentEmployment_${index}`}
                      value="yes"
                      checked={item.mbts_isCurrentEmployment === "yes"}
                      onChange={(e) => handleChangeCheckBox(e, index, "mbts_isCurrentEmployment")}
                    />Yes
                  </Label>
                </span>
                <span>
                  <Label check>
                    <Input
                      type="radio"
                      name={`mbts_isCurrentEmployment_${index}`}
                      value="no"
                      checked={item.mbts_isCurrentEmployment === "no"}
                      onChange={(e) => handleChangeCheckBox(e, index, "mbts_isCurrentEmployment")}
                    />No
                  </Label>
                </span>
              </div>
              <div className="editProfFrmBx">
                <div className="editFrmOuter">
                  {/* <div className="frmBx">
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" />
                                                You Are Currently Working ?
                                            </Label>
                                        </FormGroup>
                                    </div> */}
                  <div className="frmBx">
                    <FormGroup className={`form_grp ${item?.errors?.titleEmpty ? 'errorCont' : ''}`}>
                      <Input type="Name" className='error' name="mbts_title" onChange={(e) => handleChange3(e, index)} value={item.mbts_title} id="exampleEmail" placeholder="Job Title" />
                      <small className="error_msg">{item.errors?.titleEmpty}</small>

                    </FormGroup>
                  </div>
                  <div className="frmBx">
                    <FormGroup className={`form_grp ${item?.errors?.employmentTypeEmpty ? 'errorCont' : ''}`}>
                      <Input type="select" className='error' name="mbts_employment_type" onChange={(e) => handleChange3(e, index)} value={item.mbts_employment_type} id="exampleEmail">
                        <option>Employment Type</option>
                        <option>Full Time</option>
                        <option>Part Time</option>
                      </Input>
                      <small className="error_msg">{item.errors?.employmentTypeEmpty}</small>
                      <FiChevronDown />
                    </FormGroup>
                  </div>
                  <div className="frmBx">
                    <FormGroup className={`form_grp ${item?.errors?.companyNameEmpty ? 'errorCont' : ''}`}>
                      <Input type="Name" className='error' name="mbts_company_name" onChange={(e) => handleChange3(e, index)} value={item.mbts_company_name} id="exampleEmail" placeholder="Company" />
                      <small className="error_msg">{item.errors?.employmentTypeEmpty}</small>
                    </FormGroup>
                  </div>
                </div>
                <div className="editFrmOuter">
                  <div className="frmBx">
                  <FormGroup className={`form_grp ${item?.errors?.locationEmpty ? 'errorCont' : ''}`}>
                      <Input type="Name" className='error' name="mbts_location" onChange={(e) => handleChange3(e, index)} value={item.mbts_location} id="exampleEmail" placeholder="Location" />
                    <small className="error_msg">{item.errors?.locationEmpty}</small>
                    </FormGroup>
                  </div>
                  <div className="frmBx">
                  <FormGroup className={`form_grp ${item?.errors?.industryEmpty ? 'errorCont' : ''}`}>
                      <Input type="select" className='error' name="mbts_industry" onChange={(e) => handleChange3(e, index)} value={item.mbts_industry} id="exampleEmail">
                        <option>Industry</option>
                        <option>It Software</option>
                        <option>IT</option>
                      </Input>
                      <FiChevronDown />
                      <small className="error_msg">{item.errors?.industryEmpty}</small>
                    </FormGroup>
                  </div>
                  <div className="frmBx">
                  <FormGroup className={`form_grp ${item?.errors?.profileHeadlineEmpty ? 'errorCont' : ''}`}>
                      <Input type="Name" className='error' name="mbts_profile_headline" onChange={(e) => handleChange3(e, index)} value={item.mbts_profile_headline} id="exampleEmail" placeholder="Profile Headline" />
                      <small className="error_msg">{item.errors?.profileHeadlineEmpty}</small>
                    </FormGroup>
                  </div>
                </div>
                <div className="editFrmOuter">
                <div className="frmBx">
                    <FormGroup className={`form_grp ${item?.errors?.mbts_totalYearEmpty ? 'errorCont' : ''}`}>
                      <input
                        type="text"
                        name="mbts_totalYear"
                        value={item.mbts_totalYear}
                        onChange={(e) => handleChange3(e, index)}
                        placeholder="years"
                        className={`form_ctrl form_sel cal_icon  ${item.errors?.mbts_totalYearEmpty ? 'error' : ''}`}
                        id="exampleSelect"
                      />
                      <small className="error_msg">{item.errors?.mbts_totalYearEmpty}</small>
                    </FormGroup>
                  </div>
                  <div className="frmBx">
                    <FormGroup className={`form_grp ${item?.errors?.mbts_totalMonthEmpty ? 'errorCont' : ''}`}>
                      <input
                        type="text"
                        name="mbts_totalMonth"
                        value={item.mbts_totalMonth}
                        onChange={(e) => handleChange3(e, index)}
                        placeholder="months"
                        className={`form_ctrl form_sel cal_icon  ${item.errors?.mbts_totalMonthEmpty ? 'error' : ''}`}
                        id="exampleSelect"
                      />
                      <small className="error_msg">{item.errors?.mbts_totalMonthEmpty}</small>
                    </FormGroup>
                  </div>
                  {/* <div className="frmBx study">
                  <FormGroup className={`form_grp ${item?.errors?.startMonthEmpty ? 'errorCont' : ''}`}>
                      <Input type="select" className='error' name="mbts_startMonth" onChange={(e) => handleChange3(e, index)} value={item.mbts_startMonth} id="exampleEmail">
                        <option>Start Month</option>
                        <option>January</option>
                        <option>February</option>
                        <option>March</option>
                        <option>April</option>
                        <option>May</option>
                        <option>June</option>
                        <option>July</option>
                        <option>August</option>
                        <option>September</option>
                        <option>October</option>
                        <option>November</option>
                        <option>December</option>
                      </Input>
                      <FiChevronDown />
                       <small className="error_msg">{item.errors?.startMonthEmpty}</small>
                    </FormGroup>
                  </div>
                  <div className="frmBx study">
                    <YearDropdown handleChangeYear={handleChange3} mbts_endYear={item.mbts_endYear} mbts_startYear={item.mbts_startYear} startYearEmpty={item?.errors?.startYearEmpty}
                      index={index}
                      T="startYear" />
                    <FiChevronDown />
                  </div>
                  <div className="frmBx study">
                  <FormGroup className={`form_grp ${item?.errors?.endMonthEmpty ? 'errorCont' : ''}`}>
                      <Input type="select" className='error' name="mbts_endMonth" onChange={(e) => handleChange3(e, index)} value={item.mbts_endMonth} id="exampleEmail">
                        <option>End Month</option>
                        <option>January</option>
                        <option>February</option>
                        <option>March</option>
                        <option>April</option>
                        <option>May</option>
                        <option>June</option>
                        <option>July</option>
                        <option>August</option>
                        <option>September</option>
                        <option>October</option>
                        <option>November</option>
                        <option>December</option>
                      </Input>
                      <FiChevronDown />
                      <small className="error_msg">{item.errors?.endMonthEmpty}</small>
                    </FormGroup>
                  </div>
                  <div className="frmBx study">
                    <EndYearDropdown handleChangeYear={handleChange3} mbts_startYear={item.mbts_startYear} mbts_endYear={item.mbts_endYear} endYearEmpty={item?.errors?.endYearEmpty}
                      index={index}
                      T="endYear" />
                    <FiChevronDown />
                  </div> */}
                </div>
                <div className="textareaFrmOuter">
                  <FormGroup>
                    <Input type="textarea" name="mbts_description" onChange={(e) => handleChange3(e, index)} value={item.mbts_description} id="exampleText" placeholder="Description" />
                  </FormGroup>
                </div>

              </div>
            </Form>
            <div className="removeBtn">
              {/* <button onClick={() => removeRow(edu.id)}>
              <MdRemoveCircle /> Remove
            </button> */}
              {index !== 0 && (
                // <div className="remove_buttonBx">                          
                //     <button onClick={() => removeRow(index)}>
                //         <MdRemoveCircle /> Remove
                //     </button>
                // </div>
                <div className="remove_buttonBx">
                  <button onClick={() => toggleConfimation(index)}>
                    <MdRemoveCircle /> Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
        <button className="addmoreBtn" onClick={addNewRow}>
          <MdAddCircle /> Add More
        </button>

        <div className="BtnsOuter">
          <button className="cancelBtn" onClick={fetchData}>Cancel</button>
          <button className="submitBtn" onClick={handleUpdate}>Update</button>
        </div>
      </div>

      <Modal isOpen={confiramtionpopup} toggle={toggleConfimation} backdrop={backdrop} keyboard={keyboard} className='mbtsModCont editData'>

        <ModalBody>
          <Button
            className="popCloseBtn"
            type="button"
            onClick={toogleCancel}>
            <img src={close} alt="toggleDelete" />
          </Button>
          <div className="sure_message">
            <i><img src={Warning} /></i>
            <h2>Are You Sure?</h2>
            <span>You want to delete the Question?</span>
            <div className="modal_button">
              <Button className="cancel_button" onClick={toogleCancel}>No</Button>
              <Button className="confirmation_button" onClick={() => removeRow(index)}>Yes</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </>

  );
}

export default Experince;