import React from 'react'
import Header from "../Components/CommonComponents/Header/Header";
import Sidebar from '../Components/CommonComponents/Sidebar/Sidebar';
import Changepassword from '../Components/Changepassword/Changepassword';
import Footer from '../Components/CommonComponents/Footer/Footer'

const ChangepasswordRoutes = () =>{ 
  
    return(
        <div className=''>      
        <Changepassword />     
        
        </div>
    )
}
export default ChangepasswordRoutes;