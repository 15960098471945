import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import brainLogo from '../../assets/mobnav.png';
import '../Login/login.css'
import SuccessRegister from './successregister';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import { Button, Modal, ModalBody } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import newLogo from "../../assets/dash_updated_logo.png";


const isEmailValid = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
};

const AdminRegister = () => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [emailerror, setEmailError] = useState("");
    const [inputValues, setInputValues] = useState({
        email: "",
        password: "",
    });
    const [adminId, setAdminId] = useState();

    const inputEvent = (e) => {       
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        // Define an async function to fetch data from your API
        async function fetchData() {
            try {
                const response = await Axios.get('/admin/cheakAdminExistance');
                const roleRes = await Axios.get('/admin/getRole');

                const userObject = roleRes.data.data.find(role => role.mbts_role_name === "ADMIN");
                let role_id = userObject._id;
                setAdminId(role_id)

                if (response.data.code == 200) {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // setLoading(false);
            }
        }

        fetchData(); // Call the fetchData function when the component mounts
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setEmailError("");
        if (!isEmailValid(inputValues.email)) {
            console.log("70==")
            setEmailError('Please enter a valid email address');
            return;
        }

        let value = {
            mbts_email: inputValues.email,
            mbts_password: "",
            mbts_category: adminId
        }

        Axios.post("/admin/adminRegister", value)
            .then((response) => {               
                localStorage.setItem('token', JSON.stringify(response.data.data.token))

                if (response.data.code === 500) {
                    const message = response.data.data;
                    toast.error(message, {
                        position: 'top-right',
                    });
                }
                else if (response.data.code === 200) {                    
                    setModalOpen(true);
                }

                console.log("register response", response)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <>
            <div className="container-fluid adminLogin fade_wrapper">
                <div className='login_wrapper box_wrapper'>
                    <div className='form_logo'>
                        {/* <img src={brainLogo} alt="logo" /> */}
                        <img src={newLogo} alt="logo" />

                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group form_grp">
                            {/* <label for="exampleInputEmail1">Email address  <span  className="errormsg">*</span></label> */}
                            <input type="text" className="form-control form_ctrl" value={inputValues.email} onChange={inputEvent} name="email" id="email" aria-describedby="emailHelp" placeholder="Enter email"

                            />
                            <small className='error_msg'>{emailerror}</small>

                        </div>
                        <button type="submit" className="btn btn-primary loginButtons popFBtn  wd-100"  >Register</button>
                        {/* <SuccessRegister/> */}
                    </form>
                </div>
                <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} className='mbtsModCont'>
                    <ModalBody>
                        <SuccessRegister />
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default AdminRegister
