
import React, { Component } from "react";
import ReactDOM from 'react-dom/client';

// import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";

import Layout from "./layout";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
console.log("15=====",process.env.REACT_APP_BASE_URL , axios.defaults.baseURL)

class App extends Component {
  render() {

    return (
        <div>
          <ToastContainer/>
          <Layout />
          {/* <h2>testing for admin.........................</h2> */}
        </div>
      

    );

  }

}




export default App;
