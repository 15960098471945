import React, { useState, useEffect } from "react";
import { CitySelect, CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";



const CountryStateCity = (props) => {

  const [countryid, setCountryid] = useState();
  const [stateid, setstateid] = useState(0);
  const [countryName, setContryName] = useState("Select Country");
  const [stateName, setStateName] = useState("Select State");
  const [cityName, setSetCityName] = useState("");


  const handleChange = (e) => {
    console.log("28====", e);
    setCountryid(e);
    setContryName(e.id);

    e = {
      target: {
        name: "country",
        value: e,
      }
    }

    props.handleChange4(e)
    console.log("14==", e)
  }
  const handleChange1 = (e) => {
    console.log("35===", e)
    setstateid(e.id);

    e = {
      target: {
        name: "state",
        value: e
      }
    }

    props.handleChange4(e)
    console.log("14==", e)
  }

  const handleChange2 = (e) => {
    setstateid(stateid);
    e = {
      target: {
        name: "city",
        value: e.name
      }
    }

    props.handleChange4(e)
    console.log("14==", e)
  }

  const handleChange3 = (e) => {
    console.log("64", e)
    e = {
      target: {
        name: "city",
        value: e
      }
    }

    props.handleChange4(e)
    console.log("14==", e)
  }


  useEffect(() => {

    //setCountryid(props.country_id);
    setCountryid(props.country);
    setContryName(props?.country?.id)
    setStateName(props.state);
    setSetCityName(props.city);
    setstateid(props?.state?.id)
  }, [props?.country_id]);
  console.log("countryid==", countryid, stateName, cityName, countryid?.name, props.countryEmpty , props?.stateEmpty);
  return (
    <div className="stateCountry sccFld">
      <div  className={`fld_csc ${props?.countryEmpty ? 'error_fld' : ''}`}>
        <CountrySelect
          defaultValue={countryid}
          onChange={handleChange}
          placeHolder="Select Country"
        //value=""        
        />
      <span className={`error_msg ${props?.countryEmpty ? 'csc_msg' : ''}`}>{props?.countryEmpty}</span>
    
      </div>
      <div  className={`fld_csc ${props?.countryEmpty ? 'error_fld' : ''}`}>
      <StateSelect
        //defaultValue={stateName}
        value={stateName}
        countryid={countryName}
        onChange={handleChange1}
        placeHolder={stateName?.name || "Select State"}
      />
      <span className={`error_msg ${props?.stateEmpty ? 'csc_msg' : ''}`}>{props?.stateEmpty}</span>

      </div>
      <div  className={`fld_csc ${props?.countryEmpty ? 'error_fld' : ''}`}>
      <CitySelect
        defaultValue={cityName}
        countryid={countryName}
        stateid={stateid}
        onChange={handleChange3}
        placeHolder="Select City"
      />
      <span className={`error_msg ${props?.cityEmpty ? 'csc_msg' : ''}`}>{props?.cityEmpty}</span>

      </div>
    </div>
    //   <div>
    //   <h6>Country</h6>
    //   <select
    //     onChange={(e) => {
    //       console.log('66====',e);
    //       const country = stateList[e.target.value]; //here you will get full country object.
    //       console.log('67===',country);
    //       setCountryid(country.id);
    //       GetState(country.id).then((result) => {
    //         setStateList(result);
    //       });
    //     }}
    //     value={countryid}
    //   >
    //     {countriesList.map((item, index) => (
    //       <option key={index} value={index}>
    //         {item.name}
    //       </option>
    //     ))}
    //   </select>
    //   <h6>State</h6>
    //   <select
    //     onChange={(e) => {
    //       const state = stateList[e.target.value]; //here you will get full state object.
    //       setStateid(state.id);
    //       GetCity(countryid, state.id).then((result) => {
    //         setCityList(result);
    //       });
    //     }}
    //     value={stateid}
    //   >
    //     {stateList.map((item, index) => (
    //       <option key={index} value={index}>
    //         {item.name}
    //       </option>
    //     ))}
    //   </select>
    //   <h6>City</h6>
    //   <select
    //     onChange={(e) => {
    //       const city = cityList[e.target.value]; //here you will get full city object.
    //       setCityid(city.id);
    //     }}
    //     value={cityid}
    //   >
    //     {cityList.map((item, index) => (
    //       <option key={index} value={index}>
    //         {item.name}
    //       </option>
    //     ))}
    //   </select>
    //   <h6>Language</h6>
    //   <select
    //     onChange={(e) => {
    //       setLanguage(e);
    //     }}
    //     value={language}
    //   >
    //     {languageList.map((item, index) => (
    //       <option key={index} value={item.id}>
    //         {item.name}
    //       </option>
    //     ))}
    //   </select>
    // </div>
  );
}

export default CountryStateCity