import React, { useState, useEffect } from "react";
import Axios from 'axios'
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import DateRangeComp from "../DateRange/dateRange";
import './enquiry.css';
import moment from 'moment'
import NoDataFound from '../assets/nodata.svg'

import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoChatboxEllipses } from "react-icons/io5";


const Enquiry = (props) => {
    const [dateRange, setDateRange] = useState();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [dataPerPage, setDataPerPage] = useState(0)
    const [enquiryList, setEnquiryList] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [startIndex, setStartIndex] = useState(0)
    const [selectedInquiry, setSelectedInquiry] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');



    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };
    const handleDateRangeChange = (selectedDates) => {
      
        if (selectedDates && selectedDates.length === 2) {
            setStartDate((selectedDates[0]));
             setEndDate((selectedDates[1]));
          
        }
      };

    const getEnquiryDetails = () => {
        let authenticationToken = localStorage.getItem('token');
        const startIndexCalc = (page - 1) * limit + 1;
        setStartIndex(startIndexCalc);
        Axios.get(`/getContactUsList`, {
            params: {
                search: search,
                page: page,
                limit: limit,
                startDate:startDate,
                endDate :endDate
            },
            headers: {
                Authorization: `Bearer ${authenticationToken}`
            }
        })
            .then((response) => {
                let pageData = Math.ceil(response.data.data.total / limit)
                setEnquiryList(response.data.data.contactUsList)
                setTotalCount(response.data.data.total)
                setDataPerPage(pageData)
            })
            .catch((error) => {

            })
    }
    useEffect(() => {
        getEnquiryDetails()
    }, [])
    useEffect(() => {
        getEnquiryDetails()
    }, [page])
    useEffect(() => {
        getEnquiryDetails()
    }, [startDate,endDate])

    useEffect(() => {
        getEnquiryDetails()
    }, [search])

    const handlePageClick = (event) => {
        setPage(event.selected + 1)
    };
    const openModal = (inquiry) => {
        // setSelectedInquiry(inquiry.message);
        setSelectedInquiry(inquiry);
        toggle();
    };
    
    const toggle1 = (email) => {
        const emailLink = `mailto:${email}`;
        window.location.href = emailLink;
    };
   
    return (
        <>
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '10px', marginBottom: '10px' }}>
                            <div className="student_Title" >
                                <h1>Inquiries Status: {totalCount}</h1>
                            </div>
                            <div className="srchByfltOuter">
                                <div className="frmBx study srchBy">

                                    <DateRangeComp dateRange={[startDate, endDate]} setDateRange={handleDateRangeChange} />

                                    {/* <DateRangeComp dateRange={dateRange} setDateRange={setDateRange} /> */}
                                </div>
                                <div className="input-group">
                                    <div className="form-outline">
                                        <input type="search" placeholder="Search" name="search" value={search} onChange={(e) => setSearch
                                            (e.target.value)} id="form1" className="form-control searchBar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tableWrapper">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{width: "5%"}}>S.No.</th>
                                        <th style={{width: "40%"}}>Name/ Email</th>
                                        {/* <th width="300">Email</th> */}
                                        <th style={{width: "25%"}}>Subject</th>
                                        <th style={{width: "10%"}}>Date</th>
                                        <th style={{width: "10%"}}>Comments</th>
                                        <th style={{width: "10%"}}>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        enquiryList.length > 0 ?
                                            enquiryList.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{startIndex + index}</td>
                                                        <td><span className="inqName">{data.name}</span> 
                                                        <span className="dtxt">{data.email}</span></td>

                                                        {/* <td>{data.email}</td> */}
                                                        <td>{data.subject}</td>
                                                        <td className="planDate">  {moment(data.createdAt).format('DD-MM-YYYY hh:mm:A')}</td>
                                                        <td>
                                                            <Button className="viewcmtBtn" onClick={() => openModal(data)}>View</Button>


                                                        </td>
                                                        <td>
                                                            <Button className="viewcmtBtn" onClick={() => toggle1(data.email)}>Email</Button>


                                                        </td>
                                                        {/* <td>
                                                            <Button className="viewcmtBtn" onClick={toggle}>View Comments</Button>


                                                        </td> */}
                                                    </tr>

                                                )

                                            })
                                            :
                                            <tr>
                                               
                                                <td style={{ textAlign: 'center' }} colSpan={6}>
                                                    <div className="noData">
                                                        <img src={NoDataFound} alt='nodatafound' />
                                                        <p>no data found</p>                                                        
                                                        </div>
                                                    </td>
                                            </tr>

                                    }
                                    {/* <tr>
                                        <td>1</td>
                                        <td>David</td>
                                        <td>devidjohnes10@yopmail.com</td>
                                        <td>Inquiries for Subbscription Plan</td>
                                        <td className="planDate">04/01/2024<br /><small>11:35 AM</small></td>
                                        <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                    </tr> */}
                                    {/* <tr>
                                    <td>2</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>3</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>4</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>5</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>6</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>7</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>8</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>9</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>10</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div className="list-pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={dataPerPage}
                                previousLabel="previous"
                                // renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                                containerClassName="custom-pagination"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    {selectedInquiry && (
                        <>
                        <div className="cmtPop">
                            {/* <img src={chatIcon} alt="chat icon"/> */}
                            <span className="popIcn"><IoChatboxEllipses /></span>
                            <h2>comment from</h2>
                            <p className="inq_ppl">{selectedInquiry.name}</p>
                        <p> {(selectedInquiry.message)}</p>
                        </div> 

                            

                        </>
                    )}
                    <div className='modal_button' style={{ textAlign: 'center', display: 'block' }}>
                        <Button className="cancel_button" onClick={toggle}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>

            {/*Delete Modal*/}
            {/* <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <p className="commentTxt">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <div className='modal_button' style={{ textAlign: 'center', display: 'block' }}>
                            <Button className="cancel_button" onClick={toggle}>Close</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}
            {/*Delete Modal*/}

        </>
    );
}

export default Enquiry