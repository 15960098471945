import React, { useState, useEffect } from "react";
import Axios from 'axios'
import { TabContent, TabPane, Nav, NavItem, FormFeedback, FormText, NavLink, Card, Button, CardTitle, CardText, Row, Col, Input, Label, Form, FormGroup } from 'reactstrap';
import './EditProfile.css'
import Switch from "react-switch";
import CountryStateCity from "./country-state-city"
import classnames from 'classnames';
import Education from "./Education";
import Experince from "./Experince";
import { FiChevronDown } from "react-icons/fi"
import ProfileImg from "../../src/assets/prof-img.jpg"
import { useParams } from 'react-router-dom';
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserImage from '../assets/userImage.jpeg'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


const initialPersonalDetail = {
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    zipcode: "",
    bio: "",
    country: "",
    state: "",
    city: "",
    country_id: "",
    mbts_phone_number: "",
    errors: {
        userFirstNameEmpty: "",
        userLastNameEmpty: "",
        emailEmpty: "",
        address1Empty: "",
        countryEmpty: "",
        stateEmpty: "",
        cityEmpty: "",
        zipCodeEmpty: "",
        phoneNumberEmpty: "",
    },

};
const initialEducation = {
    mbts_school: "",
    mbts_degree: "Degree",
    mbts_field_Of_study: "Field of Study",
    mbts_grade: "",
    mbts_startMonth: "Start Month",
    mbts_startYear: "",
    mbts_endMonth: "End Month",
    mbts_endYear: "",
    mbts_description: "",
};
const initialExperience = {
    mbts_isCurrentEmployment: "yes",
    mbts_title: "",
    mbts_employment_type: "Employment Type",
    mbts_company_name: "",
    mbts_location: "",
    mbts_profile_headline: "",
    mbts_industry: "",
    mbts_startMonth: "Start Month",
    mbts_startYear: "",
    mbts_endMonth: "End Month",
    mbts_endYear: "",
    mbts_description: "",
    mbts_totalMonth:"",
    mbts_totalYear:"",
};
const EditProfile = (props) => {

    const [checked, setChecked] = useState(false);
    const [countryId, setCountryId] = useState();
    const [image, setImage] = useState();
    const [userid, setUserId] = useState();
    const [phone, setPhone] = useState();
    const [countryCode, setCountryCode] = useState("");
    // const [personalDetails , setPersonalDetails] = useState();
    const [personalDetails, setPersonalDetails] = useState(initialPersonalDetail);
    const [educationFormArray, setEducationFormArray] = useState([
        initialEducation,
    ]);
    const [experienceFormArray, setExperienceFormArray] = useState([initialExperience,]);
    const { userId } = useParams();
    // setUserId(userId);
    const [errorMessages, setErrorMessages] = useState({
        firstName: "",
        lastName: "",
        mbts_phone_number: "",
        email: "",
        address1: "",
        address2: "",
        // ... add other fields here
    });
    const {
        firstName,
        lastName,
        errors,
    } = personalDetails;
    const handleChange = (newChecked) => {
        setChecked(newChecked);
    };

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const handleChange5 = (value, country) => {
        let countryCode = country.countryCode;
        setPhone(value);
        //setCountryCode(country.countryCode);
      };
    const handleChange4 = (e) => {

        const { name, value } = e.target;
        // Update the error state

        if (e.target.id) {
            setCountryId(e.target.id);
        }
        setPersonalDetails({
            ...personalDetails,
            [name]: value,
        });

    };

    const handleChange3 = (newArray) => {
        setExperienceFormArray(newArray);
    };
    // useEffect(() => {
    //     let value

    //     Axios.post("/admin/getUserProfileData", { user_id: userId })
    //         .then((response) => {
    //             let image_url = response?.data?.data?.mbts_image_url;
    //             if (image_url) {
    //                 setImage(image_url);
    //             }

    //             if (response?.data?.data.mbts_personal_details) {

    //                 if (response?.data?.data.mbts_personal_details.length > 0) {
    //                     setPersonalDetails({
    //                         firstName:
    //                             response?.data?.data.mbts_personal_details[0].mbts_first_name,
    //                         lastName:
    //                             response.data.data.mbts_personal_details[0].mbts_last_name,
    //                         email: response.data.data.mbts_personal_details[0].mbts_email,
    //                         address1:
    //                             response.data.data.mbts_personal_details[0].mbts_address1,
    //                         address2:
    //                             response.data.data.mbts_personal_details[0].mbts_address2,
    //                         zipcode: response.data.data.mbts_personal_details[0].mbts_zipcode,
    //                         bio: response.data.data.mbts_personal_details[0].mbts_bio,
    //                         country_id: response.data.data.mbts_personal_details[0].mbts_country_id,
    //                         country: response.data.data.mbts_personal_details[0].mbts_coountry,
    //                         state: response.data.data.mbts_personal_details[0].mbts_state,
    //                         city: response.data.data.mbts_personal_details[0].mbts_city,
    //                         mbts_phone_number: response.data.data.mbts_personal_details[0].mbts_phone_number

    //                     });
    //                 }
    //                 else {
    //                     let config = {
    //                         params: {
    //                             user_id: userId,
    //                         },
    //                     }
    //                     Axios.get("/admin/getUserDetails", config)
    //                         .then((response) => {
    //                             setPersonalDetails({
    //                                 firstName:
    //                                     response?.data?.data.mbts_firstName
    //                                 ,
    //                                 lastName:
    //                                     response.data.data.mbts_lastName,
    //                                 email: response.data.data.mbts_email,
    //                             });
    //                         })
    //                         .catch((error) => { })

    //                 }
    //             }

    //             else {
    //                 let config = {
    //                     params: {
    //                         user_id: userId,
    //                     },
    //                 }
    //                 Axios.get("/admin/getUserDetails", config)
    //                     .then((response) => {
    //                         setPersonalDetails({
    //                             firstName:
    //                                 response?.data?.data.mbts_firstName
    //                             ,
    //                             lastName:
    //                                 response.data.data.mbts_lastName,
    //                             email: response.data.data.mbts_email,
    //                         });
    //                     })
    //                     .catch((error) => { })

    //             }

    //             if (response.data.data.mbts_education_details.length > 0) {
    //                 setEducationFormArray(response.data.data.mbts_education_details);
    //             }

    //             //save value of experience
    //             if (response.data.data.mbts_experience.length > 0) {
    //                 setExperienceFormArray(response.data.data.mbts_experience);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error in getUserDetails request:", error);
    //         })

    // }, [userId]);

    const fetchData = async (userid) => {
        try {
            console.log("221=====", userId)
            const response = await Axios.post("/admin/getUserProfileData", { user_id: userId });

            let image_url = response?.data?.data?.mbts_image_url;
            if (image_url) {
                setImage(image_url);
            }

            if (response?.data?.data.mbts_personal_details) {
                if (response?.data?.data.mbts_personal_details.length > 0) {
                    // setPersonalDetails({
                    //     firstName: response?.data?.data.mbts_personal_details[0].mbts_first_name,
                    //     lastName: response.data.data.mbts_personal_details[0].mbts_last_name,
                    //     email: response.data.data.mbts_personal_details[0].mbts_email,
                    //     address1: response.data.data.mbts_personal_details[0].mbts_address1,
                    //     // Add other fields here
                    // });
                    setPersonalDetails({
                        firstName:
                            response?.data?.data.mbts_personal_details[0].mbts_first_name,
                        lastName:
                            response.data.data.mbts_personal_details[0].mbts_last_name,
                        email: response.data.data.mbts_personal_details[0].mbts_email,
                        address1:
                            response.data.data.mbts_personal_details[0].mbts_address1,
                        address2:
                            response.data.data.mbts_personal_details[0].mbts_address2,
                        zipcode: response.data.data.mbts_personal_details[0].mbts_zipcode,
                        bio: response.data.data.mbts_personal_details[0].mbts_bio,
                        country_id: response.data.data.mbts_personal_details[0].mbts_country_id,
                        country: response.data.data.mbts_personal_details[0].mbts_coountry,
                        state: response.data.data.mbts_personal_details[0].mbts_state,
                        city: response.data.data.mbts_personal_details[0].mbts_city,
                        mbts_phone_number: response.data.data.mbts_personal_details[0].mbts_phone_number

                    });

                    setPhone(response.data.data.mbts_personal_details[0].mbts_phone_number);
                } else {
                    let config = { params: { user_id: userId } };
                    const userDetailsResponse = await Axios.get("/admin/getUserDetails", config);
                    setPersonalDetails({
                        firstName: userDetailsResponse?.data?.data.mbts_firstName,
                        lastName: userDetailsResponse.data.data.mbts_lastName,
                        email: userDetailsResponse.data.data.mbts_email,
                        address1:
                            "",
                        address2:
                            "",
                        zipcode: "",
                        bio: "",
                        country_id: "",
                        country: "",
                        state: "",
                        city: "",
                        mbts_phone_number: ""
                    });
                }
            } else {
                let config = { params: { user_id: userId } };
                const userDetailsResponse = await Axios.get("/admin/getUserDetails", config);
                setPersonalDetails({
                    firstName: userDetailsResponse?.data?.data.mbts_firstName,
                    lastName: userDetailsResponse.data.data.mbts_lastName,
                    email: userDetailsResponse.data.data.mbts_email,
                    address1:
                        "",
                    address2:
                        "",
                    zipcode: "",
                    bio: "",
                    country_id: "",
                    country: "",
                    state: "",
                    city: "",
                    mbts_phone_number: ""
                });
            }

            if (response.data.data.mbts_education_details.length > 0) {
                setEducationFormArray(response.data.data.mbts_education_details);
            }

            if (response.data.data.mbts_experience.length > 0) {
                setExperienceFormArray(response.data.data.mbts_experience);
            }
        } catch (error) {
            console.error("Error in getUserDetails request:", error);
        }
    };

    useEffect(() => {
        // Call fetchData inside useEffect
        fetchData(userId);
    }, [userId]);

    // const validatePersonalDetails = () => {
    //     console.log("240===", personalDetails)
    //     let isValid = true;
    //     let errorMessagesCopy = { ...errorMessages };

    //     // Validate first name
    //     if (!personalDetails.firstName || !personalDetails.firstName.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.firstName = "First Name is required";
    //     } else {
    //         errorMessagesCopy.firstName = ""; // Clear the error if valid
    //     }
    //     if (!personalDetails.firstName || !personalDetails.firstName.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.lastName = "Last Name is required";
    //     } else {
    //         errorMessagesCopy.lastName = ""; // Clear the error if valid
    //     }
    //     if (!personalDetails.mbts_phone_number || !personalDetails.mbts_phone_number.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.mbts_phone_number = "Phone Number is required";
    //     } else {
    //         errorMessagesCopy.mbts_phone_number = ""; // Clear the error if valid
    //     }
    //     if (!personalDetails.email || !personalDetails.email.trim()) {
    //         isValid = false;
    //         errorMessagesCopy.email = "Phone Number is required";
    //     } else {
    //         errorMessagesCopy.email = ""; // Clear the error if valid
    //     }

    //     // Add other validations for personal details fields here
    //     console.log("240===", isValid, errorMessagesCopy)
    //     return { isValid, errorMessages: errorMessagesCopy };
    // };

    //handling vailidation 
    const handleValidation = () => {
        let formIsValid = true;
        const newErrors = {
            userFirstNameEmpty: "",
            userLastNameEmpty: "",

        };

        if (!/^[A-Za-z]+$/.test(personalDetails.firstName)) {
            newErrors.userFirstNameEmpty = "Please enter first name.";
            formIsValid = false;
        }

        if (!/^[A-Za-z]+$/.test(personalDetails.lastName)) {
            newErrors.userLastNameEmpty = "Please enter last name.";
            formIsValid = false;
        }

        if (!personalDetails.email) {
            newErrors.emailEmpty = "Please enter email ID.";
            formIsValid = false;
        }
        else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(personalDetails.email)) {
            newErrors.emailEmpty = "Email format is not valid";
            formIsValid = false;
        }
        if (!personalDetails.address1) {
            newErrors.address1Empty = "Please enter address.";
            formIsValid = false;
        }
        // if (!personalDetails.mbts_phone_number) {
        //     newErrors.phoneNumberEmpty = "Please enter phone number.";
        //     formIsValid = false;
        // }
        if (!personalDetails.country) {
            newErrors.countryEmpty = "Please enter country.";
            formIsValid = false;
        }
        if (!personalDetails.city) {
            newErrors.cityEmpty = "Please enter city.";
            formIsValid = false;
        }
        if (!personalDetails.state) {
            newErrors.stateEmpty = "Please enter state.";
            formIsValid = false;
        }
        if (!phone) {
            newErrors.phoneNumberEmpty = "Please enter phone number.";
            formIsValid = false;
        }
        if (!personalDetails.zipcode) {
            console.log("414=======",personalDetails.zipcode);
            newErrors.zipCodeEmpty = "Please enter zipCode.";
            formIsValid = false;
        }

        console.log("275==++++", newErrors);
        setPersonalDetails({
            ...personalDetails,
            errors: newErrors,
        });

        return formIsValid;
    }
    const handleUpdate = async function (id) {
        try {
            console.log("285====")
            const formIsValid = handleValidation();
            console.log("275===!!!!", formIsValid, errors);
            // let isValid = true;
            // let errorMessagesCopy = { ...errorMessages }; // Create a copy of the errorMessages state
            // if (activeTab === "1") {
            //     // Validate personal details
            //     const personalDetailsValidation = validatePersonalDetails();
            //     console.log("268====", personalDetailsValidation)
            //     isValid = personalDetailsValidation.isValid;
            //     errorMessagesCopy = { ...errorMessagesCopy, ...personalDetailsValidation.errorMessages };
            // }

            // if (!isValid) {
            //     // Set the updated error messages state
            //     setErrorMessages(errorMessagesCopy);
            //     // Form is not valid, you can handle this as needed
            //     return;
            // }
            if (activeTab == "3") {
                let data = {
                    mbts_experience: experienceFormArray

                };
                let token = localStorage.getItem("token");
                const exeperienceDetailsResponse =
                    await combinedServices.createExperienceDetails(data, token);
                if (exeperienceDetailsResponse.data.code == 200) {
                    toast.success("Data Updated Successfully", {
                        position: "top-right",
                    });

                }
            }
            if (activeTab == "2") {

                let data = {
                    mbts_education_details: educationFormArray
                };
                let token = localStorage.getItem("token");
                const educationDetailsResponse =
                    await combinedServices.createEducationDetails(data, token);
                if (educationDetailsResponse.data.code == 200) {

                    toast.success("Data Updated Successfully", {
                        position: "top-right",
                    });

                }
            }
            if (activeTab == "1") {
                // isValid = validatePersonalDetails();
                console.log("288=====", personalDetails);
                if (formIsValid) {
                    // Your existing logic for updating personal details...

                    const data = {
                        userId: userId,
                        mbts_personal_details: [
                            {
                                mbts_first_name: personalDetails.firstName,
                                mbts_last_name: personalDetails.lastName,
                                mbts_phone_number: personalDetails.phone,
                                mbts_email: personalDetails.email,
                                mbts_address1: personalDetails.address1,
                                mbts_address2: personalDetails.address2,
                                mbts_state: personalDetails.state,
                                mbts_city: personalDetails.city,
                                mbts_countryCode: personalDetails.countryCode,
                                mbts_coountry: personalDetails.country,
                                mbts_state: personalDetails.state,
                                mbts_city: personalDetails.city,
                                mbts_zipcode: personalDetails.zipcode,
                                mbts_bio: personalDetails.bio,
                                mbts_country_id: countryId,
                                mbts_phone_number: phone
                            },
                        ],
                    };

                    let token = localStorage.getItem("token");
                    const personalDetailsResponse =
                        await combinedServices.createUserPersonalInfomation(data, token);
                    if (personalDetailsResponse.data.code == 200) {
                        toast.success("Data Updated Successfully", {
                            position: "top-right",
                        });

                    }
                }
            }

        } catch (err) {
            return err;
        }
    };
    console.log("390==================", personalDetails)

    return (
        <>
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div className="container">
                            <div className="student_Title mb-3" >
                                <h1>Edit Profile </h1>
                            </div>
                            <Nav tabs className="profileTab">
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}>
                                        Personal Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}>
                                        Educational Details
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggle('3'); }}>
                                        Experience Details
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="editProfOuter">
                                        <div className="profEdit_Outer">
                                            <div className="profileImage">
                                                <i>
                                                    {image ? (
                                                        <img src={image} alt="User Profile" />
                                                    ) : (
                                                        <img src={UserImage} alt="Default Profile" />
                                                    )}
                                                </i>
                                            </div>
                                            <div className="profEfit_Right">
                                                <Form>
                                                    <div className="editProfFrmBx">
                                                        <div className="editFrmOuter">
                                                            <div className="frmBx">
                                                                {/* <FormGroup className="form_grp errorCont">
                                                                    <Input type="Name" className="error" name="firstName" value={personalDetails.firstName} onChange={handleChange4} id="exampleEmail" placeholder="First Name" />
                                                                    <small className='error_msg'>error message</small>
                                                                </FormGroup> */}
                                                                <FormGroup className={`form_grp ${errors?.userFirstNameEmpty ? 'errorCont' : ''}`}>
                                                                    <Input
                                                                        type="Name"
                                                                        className="error"
                                                                        name="firstName"
                                                                        value={personalDetails.firstName}
                                                                        onChange={handleChange4}
                                                                        id="exampleEmail"
                                                                        placeholder="First Name"
                                                                    />
                                                                    <small className="error_msg">{errors?.userFirstNameEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className={`frmBx form_grp ${errors?.userLastNameEmpty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="Name" className="error" name="lastName" value={personalDetails.lastName} onChange={handleChange4} id="exampleEmail" placeholder="Last Name" />
                                                                    <small className="error_msg">{errors?.userLastNameEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className={`frmBx form_grp frmnumb ${errors?.phoneNumberEmpty ? 'errorCont' : ''}`}>
                                                                {/* <FormGroup>
                                                                    <Input type="Name" className="error" name="mbts_phone_number" value={personalDetails.mbts_phone_number} onChange={handleChange4} id="exampleEmail" placeholder="Phone Number" />
                                                                    <small className="error_msg">{errors?.phoneNumberEmpty}</small>
                                                                </FormGroup> */}
                                                                <PhoneInput
                                                                   className="error"
                                                                    country={countryCode}
                                                                    value={phone}
                                                                    onChange={handleChange5}
                                                                />
                                                                  <small className="error_msg">{errors?.phoneNumberEmpty}</small>
                                                            </div>
                                                        </div>
                                                        <div className="editFrmOuter">
                                                            <div className={`frmBx form_grp ${errors?.emailEmpty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="email" className="error" name="email" readOnly onChange={handleChange4} value={personalDetails.email} id="exampleEmail" placeholder="Email" />
                                                                    <small className="error_msg">{errors?.emailEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className={`frmBx form_grp ${errors?.address1Empty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="Name" className="error" name="address1" onChange={handleChange4} value={personalDetails.address1} id="exampleEmail" placeholder="Address1" />
                                                                    <small className="error_msg">{errors?.address1Empty}</small>
                                                                </FormGroup>
                                                            </div>
                                                            <div className="frmBx">
                                                                <FormGroup>
                                                                    <Input type="Name" name="address2" onChange={handleChange4} value={personalDetails.address2} id="exampleEmail" placeholder="Address2" />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <CountryStateCity countryEmpty={errors?.countryEmpty} stateEmpty={errors?.stateEmpty} cityEmpty={errors?.cityEmpty} country={personalDetails.country} country_id={personalDetails.country_id} city={personalDetails.city} state={personalDetails.state} handleChange4={handleChange4} />

                                                        </div>
                                                        <div className="editFrmOuter">
                                                            <div  className={`frmBx  ${errors?.zipCodeEmpty ? 'errorCont' : ''}`}>
                                                                <FormGroup>
                                                                    <Input type="Name" name="zipcode" className="error" value={personalDetails.zipcode} onChange={handleChange4} id="exampleEmail" placeholder="Zipcode" />
                                                                    <small className="error_msg">{errors?.zipCodeEmpty}</small>
                                                                </FormGroup>
                                                            </div>
                                                     
                                                            <div className="frmBx">
                                                                <FormGroup>
                                                                    <Input type="Name" name="bio" value={personalDetails.bio} onChange={handleChange4} id="exampleEmail" placeholder="Personal Bio (Married with 3 kids and I enjoy biking, fishing and jogging.)" />
                                                                </FormGroup>
                                                            </div>
                                                            <div className="frmBx">&nbsp;</div>
                                                        </div>
                                                    </div>
                                                </Form>
                                                <div className="BtnsOuter">
                                                    <button className="cancelBtn" onClick={fetchData}>Cancel</button>
                                                    <button className="submitBtn" onClick={handleUpdate}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Education educationFormArray={educationFormArray} userId={userId} />
                                    {/* <div className="BtnsOuter">
                                        <button className="cancelBtn">Cancel</button>
                                        <button className="submitBtn">Submit</button>
                                    </div> */}
                                </TabPane>
                                <TabPane tabId="3">
                                    <Experince experienceFormArray={experienceFormArray} userId={userId} />

                                    {/* <div className="BtnsOuter">
                                        <button className="cancelBtn">Cancel</button>
                                        <button className="submitBtn" onClick={handleChange3}>Submit</button>
                                    </div> */}
                                </TabPane>
                            </TabContent>



                        </div>

                    </div>

                </div>
            </div >




        </>
    )
}
export default EditProfile;
