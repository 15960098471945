//Import------------------------------------------------------------------------------------
import axios from "axios";
//END---------------------------------------------------------------------------------------

/**
 * FUNCTION STARTS FROM HERE
 */

/*  Experience Details
 * @createdBy Ankit
 */
async function createExperienceDetails(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/createExperienceDetails", data, {
    headers,
  });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
/*  Create Subscription Plan
 * @createdBy Ankit
 */
async function saveSubscriptionPlan(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/saveSubscriptionPlan", data, {
    headers,
  });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Get Subscription Plan
 * @createdBy Ankit
 */
async function getAllSubscriptionPlan(token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get("/admin/getAllSubscriptionPlan", { headers });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Experience Details
 * @createdBy Ankit
 */
async function createEducationDetails(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/createEducationDetails", data, {
    headers,
  });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Personal Details
 * @createdBy Ankit
 */
async function createUserPersonalInfomation(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/createUserPersonalInfomation", data, {
    headers,
  });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Delete Subscription
 * @createdBy Ankit
 */
async function deleteSubscription(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.delete("/admin/deleteTask?data=" + data._id, {
    headers,
  });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Delete Subscription
 * @createdBy Ankit
 */
async function updateStatusSubscription(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/updateStatusSubscription", data, {
    headers,
  });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Personal Details
 * @createdBy Ankit
 */
async function getUserProfileData(token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get("/admin/getUserProfileData", { headers });

  console.log("api response getUserProfileData:", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*Add Bank Details
 * @createdBy Ankit
 */
async function addBankDetails(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/addBankDetails", data, { headers });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*Add Bank Details
 * @createdBy Ankit
 */
async function getDetailsSubscription(_id, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get(
    "/admin/getDetailsSubscriptionForAdmin?_id=" + _id,
    { headers }
  );

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*Add Bank Details
 * @createdBy Ankit
 */
async function getDashboardData(token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get("/admin/getDashboardData", { headers });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/* Get Payment Status
 * @createdBy Ankit
 */
async function getPaymentStatus(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  console.log("252=====", token);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.get(
    `/admin/getPaymentStatus?search=${data.search}&page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
    { headers }
  );

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/* Get Payment Status
 * @createdBy Ankit
 */
async function deleteUserSubscription(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  console.log("252=====", token);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.get(
    `/admin/deleteUserSubscription?payment_intent=${data.payment_intent}`,
    { headers }
  );

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getVideoLink(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.get(`/admin/getVideoLink`, { headers });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// updateVideoLink
async function updateVideoLink(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.post(`/admin/updateVideoLink`, data, { headers });

  console.log("api response :", response.data);
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

const combinedServices = {
  createExperienceDetails,
  createEducationDetails,
  createUserPersonalInfomation,
  getUserProfileData,
  addBankDetails,
  saveSubscriptionPlan,
  getAllSubscriptionPlan,
  getDetailsSubscription,
  deleteSubscription,
  updateStatusSubscription,
  getDashboardData,
  getPaymentStatus,
  deleteUserSubscription,
  getVideoLink,
  updateVideoLink,
};

export default combinedServices;
