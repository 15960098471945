import React, { useState } from 'react';
import Axios from 'axios'
import { store } from 'react-notifications-component';
import './Forgotpassword.css'
import { Link, NavLink } from 'react-router-dom';
import LinkSent from './linksent';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';



const Login = () => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setPasswordError] = useState("");
    const [inputValues, setInputValues] = useState({
        email: "",
        password: "",
    });
    const inputEvent = (e) => {
        console.log(inputValues);
        var { name, value } = e.target;
        setInputValues((previousValue) => {
            if (name == "email") {
                return {
                    email: value,
                    password: previousValue.password
                }
            } else if (name == "password") {
                return {
                    password: value,
                    email: previousValue.email
                }
            }
        });
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // A simple email pattern
    const handleSubmit = (e) => {
        e.preventDefault();

        setEmailError("");
        setPasswordError("");

        if (inputValues.email == "") {           
            setEmailError("Please enter the valid email id");
            return false;
        } 
        else if (!emailRegex.test(inputValues.email)) {
            setEmailError("Invalid email format");
            return false;
        }else {
            let forgetValue = {
                email: inputValues.email,
            }

            Axios.post("/admin/forgetPassword", forgetValue)
                .then((response) => {           
                    // localStorage.setItem('token', JSON.stringify(response.data.data.token));

                    if (response.data.code == 200) {
                        setModalOpen(true)
                    }
                    else if (response.data.code == 500) {
                        const message = response.data.data;
                        toast.error(message, {
                            position: 'top-right',
                        });
                    }
                    console.log("login response", response)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }
    return (
        <>
            <div className="container-fluid adminLogin fade_wrapper">
                <div className='login_wrapper box_wrapper'>
                    <h1 className="pop_hd">Forgot Password</h1>
                    <p className="pop_text">Enter the email address associated with your account and we will send you a password reset link.</p>


                    <form>
                        <div  className={`form-group form_grp mb-10 ${emailerror ? 'error' : ''}`}>
                            {/* <label for="exampleInputEmail1">Email address  <span className="errormsg">*</span></label> */}
                            <input type="email"  className={`form-control form_ctrl ${emailerror ? 'error' : ''}`} name="email" value={inputValues.email} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email Address"
                                onChange={inputEvent}
                            />
                            <small className='error_msg'>{emailerror}</small>

                        </div>
                        <div className='backto_login forget_pass'>
                            <NavLink to="/" className="forget_passBtn">Back to Login</NavLink>
                        </div>

                        {/* <LinkSent /> */}

                        <button type="submit" onClick={handleSubmit} className="btn btn-primary loginButtons popFBtn  wd-100">Request Reset Link</button>
                    </form>
                    <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} className='mbtsModCont'>
                        <ModalBody>
                            <LinkSent email={inputValues.email}/>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default Login;
