import React,{ useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

// type ValuePiece = Date | null;
// type Value = ValuePiece | [ValuePiece, ValuePiece];

function DateRangeComp (props) {
  const [value, onChange] = useState();
  console.log(value)

  React.useEffect(() => {
    if(value && props?.setDateRange){
    props?.setDateRange(value)
    }
    else{
      props?.setDateRange([null, null])
    }
    
  }
  ,[value])


  return (
    <div className="dtRInner">
      <DateRangePicker onChange={onChange} value={value} />
    </div>
  );
}

export default DateRangeComp