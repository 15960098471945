import React, { useState, useEffect } from "react";
import Axios from 'axios'
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import { FiChevronDown } from "react-icons/fi";
import './AddSubscription.css';
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';

let initialFormData = {
    planFeatures: [
        { id: 'affiliateProgram', text: 'Access to the Affiliate Program.', checked: false },
        { id: 'additionalCoaching', text: 'Access to additional coaching hours.', checked: false },
        { id: 'milestoneTracking', text: 'Access to Milestone Tracking.', checked: false },
        { id: 'awardsAndRecognition', text: 'Badges, Awards and Recognition.', checked: false },
        { id: 'enhancedProgress', text: 'Enhanced overview of progress.', checked: false },
        { id: 'exercisesAndPrompts', text: 'Guided coaching exercises and prompts.', checked: false },
        { id: 'presonalizedAndGuidance', text: ' Personalized guidance and accountability from human Accountability Coaches. ', checked: false },
        { id: 'visualizationandaffirmation', text: 'Visualization and Affirmation tools.', checked: false },
        { id: 'freeTrial', text: 'A 14-day free trial is available.', checked: false },
        // ... Add other features here
    ],

    customerSupport: {
        supportType: '', // Existing property
        selectedOption: '',
    },
    accessJourneys: {
        accessJourneys: false,
        journeyInput: '',
        accessJourneysText: "",
        unlimitedJourneys: false,
        unlimitedJourneysText: ""
    },
    accountabilityPartner: {
        connectToPartner: false,
        connectToPartnerText: "",
        journeyInput: '',
        unlimitedPartners: false,
        unlimitedPartnersText: ""
    },
    liveCoaching: {
        liveSessions: false,
        liveSessionsText: '',
        sessionInput: '',
        sessionTime: '',
        oneHourCoaching: false,
        oneHourCoachingText: ''
    },
    planDetails: {
        planType: 'User', // default value
        planTitle: '',
        monthlyPrice: '',
        yearlyPrice: '',
        currencyType: '$', // default value
        isActive: false,
    },
}

const AddSubscription = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [planType, setPlanType] = useState('');
    const [subscription, setSubscription] = useState([]);
    const [disable, setDisable] = useState(false)
    // State to manage the form data
    const [formData, setFormData] = useState({
        planFeatures: [
            { id: 'affiliateProgram', text: 'Access to the Affiliate Program.', checked: false },
            { id: 'additionalCoaching', text: 'Access to additional coaching hours.', checked: false },
            { id: 'milestoneTracking', text: 'Access to Milestone Tracking.', checked: false },
            { id: 'awardsAndRecognition', text: 'Badges, Awards and Recognition.', checked: false },
            { id: 'enhancedProgress', text: 'Enhanced overview of progress.', checked: false },
            { id: 'exercisesAndPrompts', text: 'Guided coaching exercises and prompts.', checked: false },
            { id: 'presonalizedAndGuidance', text: ' Personalized guidance and accountability from human Accountability Coaches. ', checked: false },
            { id: 'visualizationandaffirmation', text: 'Visualization and Affirmation tools.', checked: false },
            { id: 'freeTrial', text: 'A 14-day free trial is available.', checked: false },
              // ... Add other features here
        ],

        customerSupport: {
            supportType: '', // Existing property
            selectedOption: '',
        },
        accessJourneys: {
            accessJourneys: false,
            journeyInput: '',
            accessJourneysText: "",
            unlimitedJourneys: false,
            unlimitedJourneysText: ""
        },
        accountabilityPartner: {
            connectToPartner: false,
            connectToPartnerText: "",
            journeyInput: '',
            unlimitedPartners: false,
            unlimitedPartnersText: ""
        },
        liveCoaching: {
            liveSessions: false,
            liveSessionsText: '',
            sessionInput: '',
            sessionTime: '',
            oneHourCoaching: false,
            oneHourCoachingText: ''
        },
        planDetails: {
            planType: 'User', // default value
            planTitle: '',
            monthlyPrice: '',
            yearlyPrice: '',
            currencyType: '$', // default value
            isActive: false,
        },
    });
    const [validationMessages, setValidationMessages] = useState({
        planType: '',
        planTitle: '',
    });
    const { id } = useParams();
    const handleSelectChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            planDetails: {
                ...prevData.planDetails,
                [field]: value,
            },
        }));
    };

    const handleInputChange3 = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            planDetails: {
                ...prevData.planDetails,
                [field]: value,
            },
        }));
    };

    // const toggleSwitch = () => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         planDetails: {
    //             ...prevData.planDetails,
    //             isActive: !prevData.planDetails.isActive,
    //         },
    //     }));
    // };
    const toggleSwitch = () => {
        setFormData((prevData) => ({
            ...prevData,
            planDetails: {
                ...prevData.planDetails,
                isActive: !prevData.planDetails.isActive,
            },
        }), () => {
            handleSubmit({ preventDefault: () => {} });
        });
    };
    // Handle checkbox change for plan features
    const handleCheckboxChange = (id) => {
        setFormData((prevData) => ({
            ...prevData,
            planFeatures: prevData.planFeatures.map((feature) =>
                feature.id === id ? { ...feature, checked: !feature.checked } : feature
            ),
        }));
        console.log("49===", formData);
    };

    // Handle input change for features with input field
    const handleInputChange = (category, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                [field]: value,
            },
        }));
    };
    const handleCheckboxChange1 = (category, field, textType, text) => {
        console.log('107=====', category, field);
        setFormData((prevData) => {
            console.log("109=====", prevData);
            const updatedData = {
                ...prevData,
                [category]: {
                    ...prevData[category],
                    [field]: !prevData[category][field],
                    [textType]: text,
                    journeyInput: !prevData[category][field] ? '' : prevData[category].journeyInput,
                },
            };
            console.log('107=====', updatedData);
            // Disable the opposite checkbox when one is selected
            if (field === 'accessJourneys') {
                updatedData[category].unlimitedJourneys = false;
            } else if (field === 'unlimitedJourneys') {
                updatedData[category].accessJourneys = false;
            }

            return updatedData;
        });
    };
    // Handle radio button change for customer support
    const handleRadioChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            customerSupport: {
                ...prevData.customerSupport,
                selectedOption: value,
            },
        }));
    };
    const handlePlanDetailsChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            planDetails: {
                ...prevData.planDetails,
                [field]: value,
            },
        }));
    };

    const handleCheckboxChange3 = (category, field, textType, text) => {
        setFormData((prevData) => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                [field]: !prevData[category][field],
                [textType]: text,
                sessionInput: !prevData[category][field] ? '' : prevData[category].sessionInput,
                sessionTime: !prevData[category][field] ? '' : prevData[category].sessionTime,

            },
        }));
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        if(id){
            fetchData();
        }
       else{
        setFormData(initialFormData)
       }
    }
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // If already loading, prevent multiple clicks
        setDisable(true);
        // Send formData to the backen
        console.log("247=====",formData);
        // Add your API call or further processing logic here
        const isAnyPlanFeatureSelected = formData.planFeatures.some(feature => feature.checked);

        // Check if at least one checkbox in the entire form (excluding planFeatures) is checked
        // const isAnyCheckboxSelected = Object.values(formData).some(value => {
        //     if (Array.isArray(value)) {
        //         return value.some(item => item.checked);
        //     } else if (value && typeof value === 'object') {
        //         return Object.values(value).some(subValue => subValue === true);
        //     }
        //     return false;
        // });

        const isAnyCheckboxSelected = Object.values(formData).some(value => {
            if (Array.isArray(value)) {
                return value.some(item => item.hasOwnProperty('checked') && item.checked);
            } else if (typeof value === 'object' && value !== null && !Array.isArray(value) && !isBoolean(value)) {
                return Object.values(value).some(subValue => subValue === true);
            }
            return false;
        });
        
        // Helper function to check if a value is a boolean
        function isBoolean(value) {
            return typeof value === 'boolean';
        }
        

        // Check if at least one of the required fields is filled
        const isAtLeastOneFieldFilled =
            formData.planDetails.monthlyPrice !== '' || formData.planDetails.yearlyPrice !== '';

        // If none of the fields are filled, display an error message
        if (!isAtLeastOneFieldFilled) {
            toast.error('Please fill at least one of Monthly Price or Yearly Price.');
            setDisable(false);
            return;
        }

        // Check if at least one radio option is selected
        const isAnyRadioSelected =
            formData.customerSupport.selectedOption ||
            formData.accessJourneys.accessJourneys ||
            formData.accountabilityPartner.connectToPartner ||
            formData.liveCoaching.liveSessions

console.log("290====",isAnyCheckboxSelected,isAnyRadioSelected,isAnyPlanFeatureSelected);
        if (!isAnyCheckboxSelected && !isAnyRadioSelected && !isAnyPlanFeatureSelected) {
            // Display toaster error message for no option being selected
            toast.error('Please select at least one feature.');
            setDisable(false);
            return;
        }

        // Check if the required fields are filled, including other fields
        const areRequiredFieldsFilled =
            formData.planDetails.planType !== '' &&
            formData.planDetails.planTitle !== '' &&
            isAtLeastOneFieldFilled;

        const newValidationMessages = {
            planType: formData.planDetails.planType ? '' : 'Plan Type is required.',
            planTitle: formData.planDetails.planTitle ? '' : 'Plan Title is required.',
            monthlyPrice: isAtLeastOneFieldFilled ? '' : 'At least one of Monthly Price or Yearly Price is required.',
            yearlyPrice: isAtLeastOneFieldFilled ? '' : 'At least one of Monthly Price or Yearly Price is required.',

            // ... other fields
        };

        console.log("231=====", newValidationMessages)
        if (!areRequiredFieldsFilled) {
            // Display toaster error message for required fields not being filled 
            setValidationMessages(newValidationMessages);
            setDisable(false);          
        }

        // Update the validation state
        setValidationMessages(newValidationMessages);
        setLoading(true);

        // Check if any validation errors
        if (Object.values(newValidationMessages).some(message => message !== '')) {
            return; // Exit early if there are validation errors
        }

        setLoading(true);
        let data = {
            _id: id,
            mbts_title: formData.planDetails.planTitle,
            mbts_monthly_price: formData.planDetails.monthlyPrice,
            mbts_yearly_price: formData.planDetails.yearlyPrice,
            mbts_type: formData.planDetails.planType,
            mbts_currency: formData.planDetails.currencyType,
            mbts_feature: {
                accessJourneys: formData.accessJourneys,
                accountabilityPartner: formData.accountabilityPartner,
                customerSupport: formData.customerSupport,
                liveCoaching: formData.liveCoaching,
                planFeatures: formData.planFeatures
            },
            status: formData.planDetails.isActive,
        }
        let token = localStorage.getItem("token");
        const subscriptionResponse =
            await combinedServices.saveSubscriptionPlan(data, token);
        if (subscriptionResponse.data.code == 200) {
            if(!id){
                setFormData(initialFormData)
            }           
            setTimeout(() => {               
                setDisable(false);
                toast.success("Data Updated Successfully", {
                    position: "top-right",
                });
            }, 1000);
        }
    };

    const handleEmailChange = (e) => {
        console.log("13========", e.target.id)
        setPlanType(e.target.id);
    };


    const handleInputChange1 = (category, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                [field]: value,
            },
        }));
    };

    useEffect(() => {
        // Call fetchData inside useEffect
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let token = localStorage.getItem("token");
            const subscriptionDetailsResponse =
                await combinedServices.getDetailsSubscription(id, token);
            if (subscriptionDetailsResponse.data.code == 200) {
                console.log("57=======", subscriptionDetailsResponse.data)
                const updatedFormData = {
                    ...formData,
                    planFeatures: formData.planFeatures.map((feature) => {
                        const matchingFeature = subscriptionDetailsResponse.data.data.mbts_feature.planFeatures.find((obj) => obj.id === feature.id);
                        return matchingFeature ? { ...feature, checked: matchingFeature.checked } : feature;
                    }),
                    customerSupport: {
                        ...formData.customerSupport,
                        // Assuming yourObject.mbts_feature.customerSupport.selectedOption is the correct property
                        selectedOption: subscriptionDetailsResponse.data.data.mbts_feature.customerSupport.selectedOption || '',
                    },
                    accessJourneys: {
                        ...formData.accessJourneys,
                        accessJourneys: subscriptionDetailsResponse.data.data.mbts_feature.accessJourneys.accessJourneys || false,
                        journeyInput: subscriptionDetailsResponse.data.data.mbts_feature.accessJourneys.journeyInput || '',
                        accessJourneysText: subscriptionDetailsResponse.data.data.mbts_feature.accessJourneys.accessJourneysText || '',
                        unlimitedJourneys: subscriptionDetailsResponse.data.data.mbts_feature.accessJourneys.unlimitedJourneys || false,
                        unlimitedJourneysText: subscriptionDetailsResponse.data.data.mbts_feature.accessJourneys.unlimitedJourneysText || '',
                    },
                    accountabilityPartner: {
                        ...formData.accountabilityPartner,
                        connectToPartner: subscriptionDetailsResponse.data.data.mbts_feature.accountabilityPartner.connectToPartner || false,
                        connectToPartnerText: subscriptionDetailsResponse.data.data.mbts_feature.accountabilityPartner.connectToPartnerText || '',
                        journeyInput: subscriptionDetailsResponse.data.data.mbts_feature.accountabilityPartner.journeyInput || '',
                        unlimitedPartners: subscriptionDetailsResponse.data.data.mbts_feature.accountabilityPartner.unlimitedPartners || false,
                        unlimitedPartnersText: subscriptionDetailsResponse.data.data.mbts_feature.accountabilityPartner.unlimitedPartnersText || '',
                    },
                    liveCoaching: {
                        ...formData.liveCoaching,
                        liveSessions: subscriptionDetailsResponse.data.data.mbts_feature.liveCoaching.liveSessions || false,
                        liveSessionsText: subscriptionDetailsResponse.data.data.mbts_feature.liveCoaching.liveSessionsText || '',
                        sessionInput: subscriptionDetailsResponse.data.data.mbts_feature.liveCoaching.sessionInput || '',
                        sessionTime: subscriptionDetailsResponse.data.data.mbts_feature.liveCoaching.sessionTime || '',
                        oneHourCoaching: subscriptionDetailsResponse.data.data.mbts_feature.liveCoaching.oneHourCoaching || false,
                        oneHourCoachingText: subscriptionDetailsResponse.data.data.mbts_feature.liveCoaching.oneHourCoachingText || '',
                    },
                    planDetails: {
                        ...formData.planDetails,
                        planType: subscriptionDetailsResponse.data.data.mbts_type, // default value
                        planTitle: subscriptionDetailsResponse.data.data.mbts_title,
                        monthlyPrice: subscriptionDetailsResponse.data.data.mbts_monthly_price,
                        yearlyPrice: subscriptionDetailsResponse.data.data.mbts_yearly_price,
                        currencyType: subscriptionDetailsResponse.data.data.mbts_currency, // default value
                        isActive: subscriptionDetailsResponse.data.data.status,
                    },
                    // Other properties here
                };
                console.log("282====", subscriptionDetailsResponse.data.data.mbts_type)
                setFormData(updatedFormData);
            }



        } catch (error) {
            console.error("Error in getUserDetails request:", error);
        }
    };

    // const handleSubmit = (event) => {    
    //     // Prevent the default form submission behavior
    //     event.preventDefault();
    //   };
    return (
        <>
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div className="subsHdBx" >
                            <h1>Add Subscription Plan</h1>
                        </div>
                        <Form >
                            <div className="editFrmOuter addsubsOuter">
                                <div className="frmBx study">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="select"
                                            id="exampleSelect"
                                            className="subsInp"
                                            value={formData.planDetails.planType}
                                            onChange={(e) => handleSelectChange('planType', e.target.value)}
                                        >
                                            <option>Select Type</option>
                                            <option>User</option>
                                            <option>Coach</option>
                                        </Input>
                                    </FormGroup>
                                    <FiChevronDown />
                                </div>
                                <div className={`frmBx study  ${validationMessages.planTitle ? 'errorCont' : ''}`}>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="planTitle"
                                            id="exampleEmail"
                                            placeholder="Plan Title"
                                            value={formData.planDetails.planTitle}
                                            onChange={(e) => handleInputChange3('planTitle', e.target.value)}
                                            className={`subsInp ${validationMessages.planTitle ? 'errorCont' : ''}`}
                                        />
                                        <small className="error_msg">{validationMessages.planTitle}</small>
                                    </FormGroup>
                                </div>
                                <div className={`frmBx study  ${validationMessages.monthlyPrice ? 'errorCont' : ''}`}>
                                    <FormGroup>
                                        <Input
                                            type="email"
                                            name="monthlyPrice"
                                            placeholder="Monthly Price"
                                            value={formData.planDetails.monthlyPrice}
                                            onChange={(e) => handleInputChange3('monthlyPrice', e.target.value)}                                
                                            className={`subsInp ${validationMessages.monthlyPrice ? 'errorCont' : ''}`}
                                        />
                                           <small className="error_msg">{validationMessages.monthlyPrice}</small>
                                    </FormGroup>
                                </div>
                                <div className="frmBx study">
                                    <FormGroup>
                                        <Input
                                            type="email"
                                            name="yearlyPrice"
                                            placeholder="Yearly Price"
                                            value={formData.planDetails.yearlyPrice}
                                            onChange={(e) => handleInputChange3('yearlyPrice', e.target.value)}
                                            className="subsInp"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="frmBx study">
                                    <FormGroup>
                                        <Input
                                            type="select"
                                            name="select"
                                            id="exampleSelect"
                                            className="subsInp"
                                            value={formData.planDetails.currencyType}
                                            onChange={(e) => handleSelectChange('currencyType', e.target.value)}
                                        >
                                            <option>Currency Type</option>
                                            <option>$</option>
                                        </Input>
                                    </FormGroup>
                                    <FiChevronDown />
                                </div>
                                <div className="frmBx study actSwitch">
                                    <label className="switch">
                                        <input type="checkbox" checked={formData.planDetails.isActive} onChange={toggleSwitch} />
                                        <span className="slider round"></span>
                                    </label>
                                    <p>{formData.planDetails.isActive ? 'Active' : 'Inactive'}</p>
                                </div>
                            </div>
                            <div className="featuresOuter">
                                <Label>Plan Features</Label>
                                <ul>
                                    {formData.planFeatures.map((feature) => (
                                        <li key={feature.id}>
                                            <FormGroup >
                                                <Label >
                                                    <Input type="checkbox" checked={feature.checked}
                                                        onChange={() => handleCheckboxChange(feature.id)} />
                                                    {feature.text}
                                                </Label>
                                            </FormGroup>
                                        </li>
                                    ))}
                                </ul>
                                <h4>Customer Support</h4>
                                <ul className="custSportList">
                                    <li>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1"
                                                    checked={formData.customerSupport.selectedOption === 'AI Chat Customer Support'}
                                                    onChange={() => handleRadioChange('AI Chat Customer Support')}
                                                />
                                                AI chat customer support.
                                            </Label>
                                        </FormGroup>
                                    </li>
                                    <li>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1"
                                                    checked={formData.customerSupport.selectedOption === 'AI & Email Customer Support'}
                                                    onChange={() => handleRadioChange('AI & Email Customer Support')}
                                                />                                                
                                                AI &amp; email customer support.
                                            </Label>
                                        </FormGroup>
                                    </li>
                                    <li>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1"
                                                    checked={formData.customerSupport.selectedOption === 'Priority Customer Support'}
                                                    onChange={() => handleRadioChange('Priority Customer Support')}
                                                />
                                                Priority customer support.
                                            </Label>
                                        </FormGroup>
                                    </li>
                                </ul>
                            </div>
                            <div className="accesJurneyOuter">
                                <div className="accJurLeft">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox"
                                                checked={formData.accessJourneys.accessJourneys}
                                                onChange={() => handleCheckboxChange1('accessJourneys', 'accessJourneys', 'accessJourneysText', 'Access to')}
                                            />
                                            Access Journeys.
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className="journyInp frmBx">
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="journeyInput"
                                            placeholder="number of journey"
                                            value={formData.accessJourneys.journeyInput}
                                            onChange={(e) => handleInputChange1('accessJourneys', 'journeyInput', e.target.value)}
                                            className="subsInp"
                                            disabled={!formData.accessJourneys.accessJourneys}
                                        /> </FormGroup>
                                </div>
                                <div className="accJurLeft">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={formData.accessJourneys.unlimitedJourneys}
                                                onChange={() => handleCheckboxChange1('accessJourneys', 'unlimitedJourneys', 'unlimitedJourneysText', ' Access unlimited Journeys')}
                                                disabled={formData.accessJourneys.accessJourneys}
                                            />
                                            Access to unlimited Journeys.
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="accesJurneyOuter">
                                <div className="accJurLeft">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox"
                                                checked={formData.accountabilityPartner.connectToPartner}
                                                onChange={() => handleCheckboxChange1('accountabilityPartner', 'connectToPartner', 'connectToPartnerText', 'Ability to connect to')}
                                            />
                                            Ability to connect with an Accountability Partner.
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className="journyInp frmBx">
                                    <FormGroup>
                                        <Input type="email" name="email" id="exampleEmail" placeholder="Accountability Number" value={formData.accountabilityPartner.partnerInput}
                                            onChange={(e) => handleInputChange1('accountabilityPartner', 'journelInput', e.target.value)}
                                            className="subsInp"
                                            disabled={!formData.accountabilityPartner.connectToPartner}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="accJurLeft">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={formData.accountabilityPartner.unlimitedPartners}
                                                onChange={() => handleCheckboxChange1('accountabilityPartner', 'unlimitedPartners', 'unlimitedPartnersText', ' Ability to connect to unlimited accountability Partners')}
                                                disabled={formData.accountabilityPartner.connectToPartner}
                                            />
                                            Ability to connect to unlimited accountability Partners.
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="accesJurneyOuter">
                                <div className="accJurLeft">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={formData.liveCoaching.liveSessions}
                                                onChange={() => handleCheckboxChange3('liveCoaching', 'liveSessions', 'liveSessionsText', 'live coaching sessions each month')}
                                            />
                                            Live coaching sessions each month.
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className="journyInp frmBx">
                                    <FormGroup>
                                        <Input type="text"
                                            name="sessionInput" id="exampleEmail" placeholder="Live Sessions" value={formData.liveCoaching.sessionInput}
                                            onChange={(e) => handleInputChange1('liveCoaching', 'sessionInput', e.target.value)}
                                            className="subsInp"
                                            disabled={!formData.liveCoaching.liveSessions}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="journyInp frmBx">
                                    <FormGroup>
                                        <Input type="text" name="sessionTime" id="exampleEmail" placeholder="Session Time in Minutes" value={formData.liveCoaching.sessionTime}
                                            onChange={(e) => handleInputChange1('liveCoaching', 'sessionTime', e.target.value)}
                                            className="subsInp"
                                            disabled={!formData.liveCoaching.liveSessions}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="accJurLeft">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={formData.liveCoaching.oneHourCoaching}
                                                onChange={() => handleCheckboxChange3('liveCoaching', 'oneHourCoaching')}
                                                disabled={formData.liveCoaching.liveSessions}
                                            />
                                            One hour coaching sessions each month.
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <div class="BtnsOuter left-align">
                                <Link to="/dashboard/add-subscription/" class="cancelBtn" onClick={handleCancel}>Cancel</Link>
                                {disable ? (
                                    <button class="submitBtn" type="submit" onClick={handleSubmit} disabled={disable}>Submit</button>
                                ) : (
                                    <button class="submitBtn" type="submit" onClick={handleSubmit} disabled={disable}>Submit</button>

                                )}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSubscription