"use client"
import React, { useState ,useEffect} from 'react';
// import Image from 'next/image';
import { Button, Modal, ModalBody } from 'reactstrap';
import Warning from '../../src/assets/warning.svg';
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// interface DeleteMilePopProps {
//     // Define your props here if there are any
// }
const DeleteMilePopProps = (props) => {
    const [modal, setModal] = useState(false);
    const [deleteInput , setDeleteInput]=useState();

    const toggle = () => {
        setModal(!modal);
       
    };
   
    useEffect(() => {
        console.log("123=====================111111",props);      
       
    }, [props]);
    const handleDelete = async() => {
        if(deleteInput == "Delete"){
        // If you want to remove an item, you need to know the index
        const indexToRemove = props.index;
    
        // Make sure the index is valid
        if (indexToRemove !== undefined && indexToRemove >= 0 && indexToRemove < props.bankDetails.length) {
          // Create a new array without the item at the specified index
          const newData = [...props.bankDetails.slice(0, indexToRemove), ...props.bankDetails.slice(indexToRemove + 1)];
    
          // Call a function to update the data (e.g., a function passed as a prop)
         console.log("32=======",newData);
      
         let data = {
             userId: props.userId,
             mbts_bankDetails: newData
         }

         console.log('470======', data);
         let token = localStorage.getItem("token");
         const addBankDetailsResponse =
             await combinedServices.addBankDetails(data, token);
         if (addBankDetailsResponse.data.code == 200) {
             toast.success("Data Deleted Successfully", {
                 position: "top-right",
             });

         }
          // Close the modal
          toggle();
          props.onSuccess();
        }
    }else{
        setModal(!modal);
        toast.error("Character Not Match ", {
            position: "top-right",
        });
    }
      };
    
      const handleInputDelete = async (e)=> {
          console.log("e.targer.value",e.target.value)
          setDeleteInput(e.target.value)
      }

    return (
        <>
            <Button className="delaccBtn"  onClick={toggle}><i>X</i><span>Delete Account</span></Button>

            <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <i><img src={Warning} alt="" /></i>
                        <h2>Delete Bank Account</h2>
                        <p>To delete the board type <span className='bld'>&quot;Delete&quot;</span> in the below text field and click on the delete
                            button.
                        </p>
                        <div className="form_grp confGrp">
                            <input type="text" className="form_ctrl" placeholder='Type' name='deleteInput' onChange={handleInputDelete}/>
                        </div>
                        <div className='modal_button'>
                            <Button className="cancel_button" onClick={toggle}>cancel</Button>
                            <Button className="confirmation_button" onClick={handleDelete}>delete</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default DeleteMilePopProps;
