"use client"
import React, { useState } from 'react';
// import Image from 'next/image';
import { Button, Modal, ModalBody } from 'reactstrap';
import Sucess from '../../src/assets/taskSuccess.svg';


const SaveAccountPop = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <>
            {/* <Button className="confirmation_button" onClick={toggle}>Save</Button> */}

            <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <i><img src={Sucess} alt="" /></i>
                        <h2>Bank Account Added</h2>
                        <p>Your bank account details have been added successfully. Now yu can start receiving the payments
                        </p>
                        <div className='modal_button centeralign'>
                            <Button className="cancel_button" onClick={toggle}>Close</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default SaveAccountPop;
