import React, { useState, useEffect } from "react";
import Axios from 'axios'
import moment from "moment";
import { store } from "react-notifications-component";
import ReactPaginate from 'react-paginate';
import Loader from '../Components/Loaders/Loader'
import ContentLoader from '../Components/Loaders/Contentloader'
import { Button, Modal, ModalHeader, Nav, TabPane, div, TabContent, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';
import close from "../assets/close.svg";
import { BsChevronDown } from "react-icons/bs";
import './Questions.css'
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiEdit } from "react-icons/bi"
import { MdDelete } from "react-icons/md";
import Warning from '../assets/warning.svg'
import { MdAddCircle } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai"
import { AiOutlineMinus } from "react-icons/ai"
import { FiChevronDown } from "react-icons/fi";
import NoDataFound from '../assets/nodata.svg'


const NewStudent = (props) => {
    const [studentList, setStudentList] = useState([])
    const [vc, setVc] = useState('')
    const [verifyVC, setVerifyVC] = useState("")
    const [vCData, setVCData] = useState('')
    const [verifiableCredential, setVerifiableCredential] = useState("")
    const [totalCount, setTotalCount] = useState('0')
    const [search, setSearch] = useState("");
    const [page, setPage] = useState("1");
    const [limit, setLimit] = useState("10");
    const [dataPerPage, setDataPerPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [deleteModel, setDeleteModel] = useState(false);
    const {
        buttonLabel,
        className
    } = props;
    const [modal, setModal] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [keyboard, setKeyboard] = useState(true);
    const [formData, setFormData] = useState({
        question: "",
        classification: '',
        category: "Category", // Default value for the category select input
        subcategory: "",
        type: ""
    });
    const [modalEdit, setModalEdit] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [userId, setUserId] = useState();
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [classification, setClassification] = useState("");
    const [type, setType] = useState("");
    const [question, setQustion] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [filter, setFilter] = useState();
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("404======", e.target);
        setFormData({
            ...formData,
            [name]: value,
        });
       
    };

    // hanlde of edit functionaility
    const handleChangeEdit = (e) => {
        const { name, value } = e.target;
        if (name == "category") {
            setCategory(value)
        }
        if (name == "subcategory") {
            setSubcategory(value)
        }
        if (name == "question") {
            setQustion(value)
        }
        if (name == "classification") {
            setClassification(value)
        }
    }

    // Function call when create question form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            mbts_question: formData.question,
            mbts_question_category: formData.category,
            mbts_classification: formData.classification,
            mbts_question_subcategory: formData.subcategory,
            mbts_question_type: selectedTab,
            mbts_question_options: optionsArray

        }

        let token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // You can add other headers as needed
        };
        Axios.post("/admin/createQuestions", data, { headers })
            .then((response) => {
                setLoadingPage(false)
                if (response.data.code === 500) {
                    const message = response.data.data;
                    toast.error(message, {
                        position: 'top-right',
                    });
                }
                else if (response.data.code === 200) {
                    //const message = response.data.data;
                    toast.success("updated successfully", {
                        position: 'top-right',
                    });
                    setModal(!modal);
                    let dbObj = {
                        page: page,
                        limit: limit,
                        search: search
                    }
                    Axios.post("/admin/getQuestion", dbObj)
                        .then((response) => {
                            const itemsPerPage = 10; // Number of items to display per page
                            let totalCount = response.data.data.totalCount;
                            const pageCounts = Math.ceil(totalCount / itemsPerPage);
                            let data = response.data.data.questionList;
                            setTotalCount(totalCount);
                            setPageCount(pageCounts);
                            setData(data);
                        })

                }

            })
            .catch((error) => {

            })
    };

    // Function call when edit question form submit
    const hanldeSubmitEdit = (e) => {
        e.preventDefault();

        let data = {
            _id: questionId,
            mbts_question: question,
            mbts_question_category: category,
            mbts_classification: classification,
            mbts_question_subcategory: subcategory,
            mbts_question_type: selectedTab,
            mbts_classification: classification,
            mbts_question_options: optionsArray

        }
        let token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // You can add other headers as needed
        };
        Axios.post("/admin/updateQuestion", data, { headers })
            .then((response) => {
                setLoadingPage(false)
                if (response.data.code === 500) {
                    const message = response.data.data;
                    toast.error(message, {
                        position: 'top-right',
                    });
                }
                else if (response.data.code === 200) {
                    //const message = response.data.data;
                    toast.success("updated successfully", {
                        position: 'top-right',
                    });
                    setModalEdit(!modalEdit);
                    let dbObj = {
                        page: page,
                        limit: limit,
                        search: search
                    }
                    Axios.post("/admin/getQuestion", dbObj)
                        .then((response) => {
                            const itemsPerPage = 10; // Number of items to display per page
                            let totalCount = response.data.data.totalCount;
                            const pageCounts = Math.ceil(totalCount / itemsPerPage);
                            let data = response.data.data.questionList;
                            setTotalCount(totalCount);
                            setPageCount(pageCounts);
                            setData(data);
                        })
                }
            })
            .catch((error) => {
                return error
            })
    }

    //handle toggle 
    const toggle = (id) => {
        setModal(!modal);

    }

    //handle toggle edit
    const toggleEdit = (id) => {
        setQuestionId(id)
        setModalEdit(!modalEdit);
        Axios.get("/admin/getQuestionWithId", { params: { id } })
            .then((response) => {
                let data = response.data.data
                setCategory(data.mbts_question_category);
                setClassification(data.mbts_classification);
                setSubcategory(data.mbts_question_subcategory);
                setSelectedTab(data.mbts_question_type);
                setQustion(data.mbts_question);
                setOptionArray(data.mbts_question_options
                );
            })

    }
    const changeBackdrop = e => {
        let value = e.target.value;
        if (value !== 'static') {
            value = JSON.parse(value);
        }
        setBackdrop(value);
    }

    const changeKeyboard = e => {
        setKeyboard(e.currentTarget.checked);
    }
    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
        let dbObj = {
            page: page,
            limit: limit,
            search: event.target.value
        }
        Axios.post("/admin/getQuestion", dbObj)
            .then((response) => {
                let data = response.data.data.questionList;
                const itemsPerPage = 10; // Number of items to display per page
                let totalCount = response.data.data.totalCount;
                setTotalCount(totalCount);
                const pageCounts = Math.ceil(totalCount / itemsPerPage);
                setPageCount(pageCounts);
                setData(data);
            })
    }
    useEffect(() => {
        let dbObj = {
            page: page,
            limit: limit,
            search: search,
            filter: filter
        }
        Axios.post("/admin/getQuestion", dbObj)
            .then((response) => {
                console.log("78====", response, response.data.data.questionList);
                let data = response.data.data.questionList;
                const itemsPerPage = 10; // Number of items to display per page
                let totalCount = response.data.data.totalCount;
                setTotalCount(totalCount);
                const pageCounts = Math.ceil(totalCount / itemsPerPage);
                setPageCount(pageCounts);
                setData(data);
            })
    }, [filter])

    const toggleDelete2 = (id) => {
        setDeleteModel(!deleteModel);
        setUserId(id)
    }
    const toggleDelete = () => {
        let token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // You can add other headers as needed
        };
        let data = {
            "_id": userId
        }
        Axios.post("/admin/deleteQuestion", data, { headers })
            .then((response) => {
                console.log("User Status Response", response);
                if (response.data.code == 200) {
                    const message = response.data.data;
                    toast.success(message, {
                        position: 'top-right',
                    });
                    setDeleteModel(!deleteModel);
                    setIsActive(response.data.data.mbts_isUserActive);
                    let dbObj = {
                        page: page,
                        limit: limit,
                        search: search
                    }

                    Axios.post("/admin/getQuestion", dbObj)
                        .then((response) => {
                            let data = response.data.data.questionList;
                            const itemsPerPage = 10;
                            let totalCount = response.data.data.totalCount
                            setTotalCount(totalCount);
                            const pageCounts = Math.ceil(totalCount / itemsPerPage);
                            setPageCount(pageCounts);
                            setData(data);
                        })
                }

            })
            .catch((error) => {

            })
    }

    const [isActive, setIsActive] = useState(false);
    const toggleSwitch = (id, status) => {
        let mbts_status = !status
        let token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // You can add other headers as needed
        };
        let data = {
            "mbts_status": mbts_status,
            "_id": id
        }
        Axios.post("/admin/updateQuestionStatus", data, { headers })
            .then((response) => {
                console.log("User Status Response", response);
                if (response.data.code == 200) {
                    setIsActive(response.data.data.mbts_isUserActive);
                    let dbObj = {
                        page: page,
                        limit: limit,
                        search: search
                    }
                    const message = "Status Updatede Successfully";
                    toast.success(message, {
                        position: 'top-right',
                    });
                    Axios.post("/admin/getQuestion", dbObj)
                        .then((response) => {
                            let data = response.data.data.questionList;
                            const itemsPerPage = 10;
                            setTotalCount(totalCount);
                            const pageCounts = Math.ceil(totalCount / itemsPerPage);
                            setPageCount(pageCounts);
                            setData(data);
                        })
                }

            })
            .catch((error) => {
                return error;
            })
    };


    const handlePageClick = (event) => {
        console.log("page event", event)
        let pageValue = event.selected + 1
        setPage(event.selected + 1);
        let dbObj = {
            page: pageValue,
            limit: limit,
            search: search
        }
        Axios.post("/admin/getQuestion", dbObj)
            .then((response) => {
                let data = response.data.data.questionList;
                const itemsPerPage = 10;
                setTotalCount(totalCount);
                const pageCounts = Math.ceil(totalCount / itemsPerPage);
                setPageCount(pageCounts);
                setData(data);
            })
    };

    const [selectedTab, setSelectedTab] = useState('objective'); // Initial selected tab
    const handleTabChange = (event) => {
        setSelectedTab(event.target.value); // Update the selected tab based on the selected option
    };
    //add clone
    const [optionsArray, setOptionArray] = useState([{ optionText: "" }]);

    const addNewRow = (e) => {
        e.preventDefault();
        setOptionArray([...optionsArray, { optionText: "" }]);
    };

    const removeRow = (index, e) => {
        e.preventDefault()
        const updatedData = [...optionsArray];
        updatedData.splice(index, 1);
        setOptionArray(updatedData);
    };

    const handleChange2 = (e, index) => {
        const { name, value } = e.target;
        let newArray = [...optionsArray];
        newArray[index] = { ...newArray[index], [name]: value };
        setOptionArray(newArray);
    };

    console.log("404======++", formData.classification);
    return (
        <>
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div className="container">
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '10px', marginBottom: '20px' }}>
                                <div className="student_Title" >
                                    <h1 style={{ fontSize: '30px' }}> Total Question : {totalCount}</h1>
                                </div>

                                <div className="quesSerch">
                                    <div className="frmBx study srchBy">
                                        <FormGroup className="form_grp">
                                            <Input type="select" name="mbts_grade" onChange={(e) => setFilter(e.target.value)} >
                                                <option value=''>All Category</option>
                                                <option>Faith</option>
                                                <option>Family</option>
                                                <option>Fitness</option>
                                                <option>Finance</option>
                                                <option>Focus</option>
                                                <option>Frame</option>
                                                <option>Fun</option>
                                                <option>Other</option>
                                            </Input>
                                            <FiChevronDown />
                                        </FormGroup>
                                    </div>
                                    <div className="frmBx study srchBy">
                                        <FormGroup className="form_grp">
                                            <Input type="select" name="mbts_grade" onChange={(e) => setFilter(e.target.value)} >
                                                <option value=''>All Classification</option>
                                                <option >Assessment</option>
                                                <option>Journey</option>
                                            </Input>
                                            <FiChevronDown />
                                        </FormGroup>
                                    </div>
                                    <div className="form-outline">
                                        <input type="search" placeholder="Search" id="form1" className="form-control searchBar" name="search" value={search} onChange={handleChangeSearch} />
                                    </div>
                                    <button className="addQuestBtn" onClick={toggle}>Add</button>
                                </div>

                            </div>
                            <div className="tableWrapper">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width="60" scope="col">S.No.</th>
                                            <th scope="col">Questions</th>
                                            <th width="140" scope="col">Category</th>
                                            <th width="140" scope="col">Classification</th>
                                            <th width="140" scope="col">Type</th>
                                            <th width="100" scope="col">CreatedAt</th>
                                            <th width="80" scope="col">Status</th>
                                            <th width="80" scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {
                                                data?.length > 0 ? (
                                                    data?.map((item, index) =>
                                                    (
                                                        <tr key={item.id}>
                                                            <td>{(page - 1) * limit + index + 1}</td>
                                                            <td>{item.mbts_question}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{item.mbts_question_category}</td>
                                                            <td>{item.mbts_classification}</td>
                                                            <td>{item.mbts_question_type}</td>

                                                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                                                            <td>
                                                                <Switch checked={item.mbts_status}
                                                                    onChange={() => toggleSwitch(item._id, item.mbts_status)} />

                                                            </td>
                                                            <td>
                                                                <button className="editButton" onClick={() => toggleEdit(item._id)}>
                                                                    <BiEdit />
                                                                </button>
                                                                {/* <button className="editButton" onClick={() => toggleDelete(item._id)}>
                                                            <MdDelete />
                                                        </button> */}
                                                                <button className="editButton" onClick={() => toggleDelete2(item._id)}>
                                                                    <MdDelete />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )
                                                ) : (
                                                    <tr>

                                                        <td style={{ textAlign: 'center' }} colSpan={11}>
                                                            <div className="noData">
                                                                <img src={NoDataFound} alt='nodatafound' />
                                                                <p>no data found</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    </tbody>
                                </table>
                            </div>

                            <div className="list-pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >


            {/* Modal for Add Questions */}
            <Modal isOpen={modal} toggle={toggle} backdrop={backdrop} keyboard={keyboard} className='mbtsModCont editData'>

                <ModalBody>
                    <Button
                        className="popCloseBtn"
                        type="button"
                        onClick={toggle}                    >
                        <img src={close} alt="close button" />
                    </Button>
                    <div className="edit_dInner">
                        <h1 className="pop_hd">Add Question</h1>
                        {/* <p className="pop_text">....</p> */}
                        {/* onSubmit={handleSubmit} */}
                        <form onSubmit={handleSubmit}>
                            <div className="dropdown form_grp eduarrow">
                                <select className="dropdown-btn form_ctrl form_sel" value={selectedTab} onChange={handleTabChange}>
                                    <option value="objective">Objective Type Question</option>
                                    <option value="mutiselect">Multiselect Question</option>
                                    <option value="descriptive">Descriptive</option>
                                </select>
                                <i><BsChevronDown /></i>
                            </div>
                            <div className="form_grp eduarrow">
                                {/* add "error" class in input field to show border red */}
                                <Input type="select" name="classification" value={formData.classification}
                                    onChange={handleChange} className="form_ctrl form_sel" id="exampleSelect">
                                    <option>Classification</option>
                                    <option >Assessment</option>
                                    <option>Journey</option>

                                </Input>
                                <i><BsChevronDown /></i>
                            </div>
                            <div className="form_grp eduarrow">
                                {/* add "error" class in input field to show border red */}
                                <Input type="select" name="category" value={formData.category}
                                    onChange={handleChange} className="form_ctrl form_sel" id="exampleSelect">
                                    <option>Category</option>
                                    <option>Faith</option>
                                    {/* <option>Faith- Tool</option> */}
                                    <option>Family</option>
                                    {/* <option>Family- Tool</option> */}
                                    <option>Fitness</option>
                                    {/* <option>Fitness - Tool</option> */}
                                    <option>Finance</option>
                                    {/* <option>Finance - Tool</option> */}
                                    <option>Focus</option>
                                    {/* <option>Focus- Tool</option> */}
                                    <option>Frame of Mind </option>
                                    {/* <option>Frame of Mind- Tool</option> */}
                                    <option>Fun</option>
                                    {/* <option>Fun- Tool</option> */}
                                    <option>Other</option>

                                </Input>
                                <i><BsChevronDown /></i>
                            </div>
                            {formData.classification == 'Journey' || formData.classification == '' ? (
                                <div className="form_grp eduarrow">
                                    {/* add "error" class in input field to show border red */}
                                    <Input type="select" name="subcategory" value={formData.subcategory} onChange={handleChange} className="form_ctrl form_sel" id="exampleSelect">
                                        <option>Sub Category</option>
                                        <option>Imagination</option>
                                        <option>Internalization</option>
                                    </Input>
                                    <i><BsChevronDown /></i>
                                </div>
                            ) : (
                                ''
                            )
                            }

                            <div className="form-group form_grp">
                                <textarea type="text" className="form-control form_ctrl" name="question" value={formData.question}
                                    onChange={handleChange} id="" placeholder="Enter Question Here" />
                            </div>
                            {selectedTab === 'objective' && (
                                <div className="choiceBx">
                                    <div className="addmoreOuterBx"><button className='addmoreBtn' onClick={addNewRow}><AiOutlinePlus /></button></div>
                                    {optionsArray.map((item, index) => (
                                        <div className="expense-block" key={index} id={`expense-block-${index}`} data-block={index}>

                                            <div className="editFrmOuter">
                                                <div className="frmBx study">
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="optionText"
                                                            value={item.optionText}
                                                            onChange={(e) => handleChange2(e, index)}
                                                            placeholder='Option'
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="removeBtn">
                                                    {index !== 0 && (
                                                        <div className="remove_buttonBx">
                                                            <button onClick={(e) => removeRow(index, e)}>
                                                                <AiOutlineMinus />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                        </div>
                                    ))}


                                </div>
                            )}
                            {selectedTab === 'mutiselect' && (
                                <div tabId="2" className="MultichoiceChoiceOuter">
                                    <div className="choiceBx">
                                        {optionsArray?.map((item, index) => (
                                            <div className="expense-block" key={index} id={`expense-block-${index}`} data-block={index}>

                                                <div className="editFrmOuter">
                                                    <div className="frmBx study">
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="optionText"
                                                                value={item.optionText}
                                                                onChange={(e) => handleChange2(e, index)}
                                                                placeholder='Option'
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="removeBtn">
                                                        {index !== 0 && (
                                                            <div className="remove_buttonBx">
                                                                <button onClick={(e) => removeRow(index, e)}>
                                                                    <AiOutlineMinus />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>


                                            </div>
                                        ))}
                                        <div className="addmoreOuterBx"><button className='addmoreBtn' onClick={addNewRow}><AiOutlinePlus /> </button></div>

                                    </div>
                                </div>
                            )}
                            {/* {selectedTab === 'descriptive' && (
                                <div tabId="3" className="longAnswerType">
                                    <div className="form-group form_grp">
                                        <textarea type="text" className="form-control form_ctrl" name="question" placeholder="Enter Your Answer Here" />
                                    </div>
                                </div>
                            )} */}
                            <button type="submit" className="btn btn-primary loginButtons popFBtn  wd-100" >Submit</button>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

            {/* Modal for Edit Questions */}
            <Modal isOpen={modalEdit} toggle={toggleEdit} backdrop={backdrop} keyboard={keyboard} className='mbtsModCont editData'>
                <ModalBody>
                    <Button
                        className="popCloseBtn"
                        type="button"
                        onClick={toggleEdit}                    >
                        <img src={close} alt="close button" />
                    </Button>
                    <div className="edit_dInner">
                        <h1 className="pop_hd">Add Question</h1>
                        {/* <p className="pop_text">....</p> */}
                        {/* onSubmit={handleSubmit} */}
                        <form onSubmit={hanldeSubmitEdit}>
                            <div className="dropdown form_grp eduarrow">
                                <select className="dropdown-btn form_ctrl form_sel" value={selectedTab} onChange={handleTabChange}>
                                    <option value="objective">Objective Type Question</option>
                                    <option value="mutiselect">Multiselect Question</option>
                                    <option value="descriptive">Descriptive</option>
                                </select>
                                <i><BsChevronDown /></i>
                            </div>
                            <div className="form_grp eduarrow">
                                {/* add "error" class in input field to show border red */}
                                <Input type="select" name="classification" value={classification}
                                    onChange={handleChange} className="form_ctrl form_sel" id="exampleSelect">
                                    <option>Classification</option>
                                    <option >Assessment</option>
                                    <option>Journey</option>

                                </Input>
                                <i><BsChevronDown /></i>
                            </div>
                            <div className="form_grp eduarrow">
                                {/* add "error" class in input field to show border red */}
                                <Input type="select" name="category" value={category}
                                    onChange={handleChangeEdit} className="form_ctrl form_sel" id="exampleSelect">
                                    <option>Category</option>
                                    <option>Faith</option>
                                    {/* <option>Faith- Assessment Tool</option> */}
                                    <option>Family</option>
                                    {/* <option>Family- Assessment Tool</option> */}
                                    <option>Fitness</option>
                                    {/* <option>Fitness - Assessment Tool</option> */}
                                    <option>Finance</option>
                                    {/* <option>Finance - Assessment Tool</option> */}
                                    <option>Focus</option>
                                    {/* <option>Focus- Assessment Tool</option> */}
                                    <option>Frame</option>
                                    {/* <option>Frame of Mind- Assessment Tool</option> */}
                                    <option>Fun</option>
                                    {/* <option>Fun- Assessment Tool</option> */}
                                    <option>Other</option>

                                </Input>
                                <i><BsChevronDown /></i>
                            </div>

                            {classification == 'Journey' || classification == '' ? (
                                <div className="form_grp eduarrow">
                                    {/* add "error" class in input field to show border red */}
                                    <Input type="select" name="subcategory" value={formData.subcategory} onChange={handleChange} className="form_ctrl form_sel" id="exampleSelect">
                                        <option>Sub Category</option>
                                        <option>Imagination</option>
                                        <option>Internalization</option>
                                    </Input>
                                    <i><BsChevronDown /></i>
                                </div>
                            ) : (
                                ''
                            )
                            }

                            <div className="form-group form_grp">
                                <textarea type="text" className="form-control form_ctrl" name="question" value={question}
                                    onChange={handleChangeEdit} id="" placeholder="Enter Question Here" />
                            </div>
                            {selectedTab === 'objective' && (
                                <div className="choiceBx">
                                    <div className="addmoreOuterBx"><button className='addmoreBtn' onClick={addNewRow}><AiOutlinePlus /></button></div>
                                    {optionsArray.map((item, index) => (
                                        <div className="expense-block" key={index} id={`expense-block-${index}`} data-block={index}>

                                            <div className="editFrmOuter">
                                                <div className="frmBx study">
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="optionText"
                                                            value={item.optionText}
                                                            onChange={(e) => handleChange2(e, index)}
                                                            placeholder='Option'
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="removeBtn">
                                                    {index !== 0 && (
                                                        <div className="remove_buttonBx">
                                                            <button onClick={(e) => removeRow(index, e)}>
                                                                <AiOutlineMinus />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {selectedTab === 'mutiselect' && (
                                <div tabId="2" className="MultichoiceChoiceOuter">
                                    <div className="choiceBx">
                                        {optionsArray.map((item, index) => (
                                            <div className="expense-block" key={index} id={`expense-block-${index}`} data-block={index}>

                                                <div className="editFrmOuter">
                                                    <div className="frmBx study">
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                name="optionText"
                                                                value={item.optionText}
                                                                onChange={(e) => handleChange2(e, index)}
                                                                placeholder='Option'
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="removeBtn">
                                                        {index !== 0 && (
                                                            <div className="remove_buttonBx">
                                                                <button onClick={(e) => removeRow(index, e)}>
                                                                    <AiOutlineMinus />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>


                                            </div>
                                        ))}
                                        <div className="addmoreOuterBx"><button className='addmoreBtn' onClick={addNewRow}><AiOutlinePlus /></button></div>

                                    </div>
                                </div>
                            )}
                            <button type="submit" className="btn btn-primary loginButtons popFBtn  wd-100" >Submit</button>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={deleteModel} toggle={toggleDelete2} backdrop={backdrop} keyboard={keyboard} className='mbtsModCont editData'>

                <ModalBody>
                    <Button
                        className="popCloseBtn"
                        type="button"
                        onClick={toggleDelete2}>
                        <img src={close} alt="toggleDelete" />
                    </Button>
                    <div className="sure_message">
                        <i><img src={Warning} /></i>
                        <h2>Are You Sure?</h2>
                        <span>You want to delete the Question?</span>
                        <div className="modal_button">
                            <Button className="cancel_button" onClick={toggleDelete2}>No</Button>
                            <Button className="confirmation_button" onClick={toggleDelete}>Yes</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
export default NewStudent;
