
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import close from "../../assets/close.svg";
import success from "../../assets/success.svg";
import { Link } from 'react-router-dom';



const SuccessRegister = () => {

  const [modal, setModal] = useState(false);

  //function to open Modal....
  const toggle = () => setModal(!modal);

  return (
    <div>
      {/* <Button
        className="popFBtn  wd-100"
        type="button"
        onClick={toggle}
      >
        Register
      </Button>
      <Modal isOpen={modal} toggle={toggle} className='mbtsModCont'>
        <ModalBody> */}
          {/* button to close popup */}         
          <Link to="/"> 
          <Button
            className="popCloseBtn"
            type="button"
            onClick={toggle}
          >
            <img src={close} alt="close button" />
          </Button>
          </Link> 
          <div className="popIconWrp">
            <img src={success} alt="success icon" />
          </div>
          <h2 className="pop_hd">Congratulations</h2>
          <p className="pop_text">
            your account has been successfully created. Please
            check your email for verification.
          </p>

          <div className="popFormBtn">
            {/* <button className="popFBtn" onClick={toggle}>
              
            </button> */}
            <Link exact to="/" className="popFBtn">Back to Login</Link>
          </div>
        {/* </ModalBody>
      </Modal> */}
    </div>
  );
};

export default SuccessRegister;
