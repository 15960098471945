import React, { useState, useEffect } from 'react';
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import brainLogo from '../../assets/mobnav.png';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hasToken } from '../../utils/tokenUtil';
const Changepassword = () => {
    const navigate = useNavigate();
    const [newpassworderror, setNewpassworderror] = useState("");
    const [confirmpassworderror, setConfirmPasswordError] = useState("");
  
    //states for show and hide password...
    const [password, setPassword] = useState("");
    const [token ,setToken]=useState();
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [inputValues, setInputValues] = useState({
        newpassword: "",
        confirmpassword: "",
    });
    const [errors, setErrors] = useState({
        newpassword: '',
        confirmpassword: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_])[A-Za-z\d@#$%^&+=!_]{8,20}$/;


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
      
        console.log("Token from URL:", token);
        let value = {
            mbts_verification_token: token
        }

        //caliing verify email api
        Axios.post("/admin/verifyEmail", value)
            .then((response) => {
                console.log("verify email response response", response);
                if (response.data.code == 200) {
                    localStorage.setItem('authenticationToken', response.data.data);
                }
                else if (response.data.code == 500) {
                    let message = response.data.data
                    toast.error(message, {
                        position: 'top-right',
                    });
                    navigate('/forgotpassword')
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }, []);


    const inputEvent = (e) => {
        console.log("64======")
        const { name, value } = e.target;
        setInputValues((previousValues) => ({
            ...previousValues,
            [name]: value,
        }));

        // Password validation
        // if (name === 'newpassword') {
        //     if (!value) {
        //         setErrors((previousErrors) => ({
        //             ...previousErrors,
        //             newpassword: 'Password is required.',
        //         }));
        //         setIsFormValid(false); // Form is not valid
        //     } else if (!passwordRegex.test(value)) {
        //         setErrors((previousErrors) => ({
        //             ...previousErrors,
        //             newpassword: 'Password must contain at least 8 characters, one lowercase, one uppercase, and one digit.',
        //         }));
        //         setIsFormValid(false); // Form is not valid
        //     } else {
        //         setErrors((previousErrors) => ({
        //             ...previousErrors,
        //             newpassword: '',
        //         }));
        //         setIsFormValid(true); // Form is valid
        //     }
        // }

        // if (name === 'confirmpassword') {
        //     if (!value) {
        //         setErrors((previousErrors) => ({
        //             ...previousErrors,
        //             confirmpassword: 'Confirm Password is required.',
        //         }));
        //         setIsFormValid(false); // Form is not valid
        //     } else if (value !== inputValues.newpassword) {
        //         setErrors((previousErrors) => ({
        //             ...previousErrors,
        //             confirmpassword: 'Passwords do not match.',
        //         }));
        //         setIsFormValid(false); // Form is not valid
        //     } else {
        //         setErrors((previousErrors) => ({
        //             ...previousErrors,
        //             confirmpassword: '',
        //         }));
        //         setIsFormValid(true); // Form is valid
        //     }
        // }
        console.log("115=====",errors);
    };


    //function to show and hide password
    const handleToggle = () => {

        setIcon(eyeOff)
        setType('confirmpassword')

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("127",inputValues)
         
        if (inputValues.newpassword  === "") {
            setNewpassworderror("Password is required");
            return false;
        }
        else{
            setNewpassworderror("");
        } 
         if (inputValues.confirmPassword === "") {
            setConfirmPasswordError("Confirm Password is required");
            return false;
         } 
         else{
            setConfirmPasswordError("");
         }
         if (!/^^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(inputValues.newpassword)) {
            setNewpassworderror("Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.");
            return false;
        } 
        else{
            setNewpassworderror("");

        }
         if (inputValues.newpassword!== inputValues.confirmpassword) {
            console.log("254=====",inputValues.newpassword ,inputValues.confirmPassword )
            setConfirmPasswordError("Passwords do not match");
            return false;
         } 
       
    
       // if (isFormValid) {
            let loginValue = {
                password: inputValues.confirmpassword
            }

            let authenticationToken = localStorage.getItem('authenticationToken');
            const headers = {
                'Authorization': `Bearer ${authenticationToken}`,
                'Content-Type': 'application/json', // You can add other headers as needed
            };

            Axios.post("/admin/resetPassword", loginValue, { headers })
                .then((response) => {
                    localStorage.setItem('token', JSON.stringify(response.data.data.token));
                    if (response.data.code === 500) {
                        const message = response.data.data;
                        toast.error(message, {
                            position: 'top-right',
                        });
                    }
                    else if (response.data.code === 200) {
                        const message = response.data.data;
                        toast.success(message, {
                            position: 'top-right',
                        });
                        navigate('/')
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
       // }

    }
    return (
        <>
            <div className="container-fluid adminLogin fade_wrapper">
                <div className='login_wrapper box_wrapper'>
                    <h1 className="pop_hd">Change Password</h1>
                    <form>
                    <div className={`form-group form_grp  ${newpassworderror ? 'error' : ''}`}>
                            <input                               
                                className={`form-control form_ctrl ${newpassworderror ? 'error' : ''}`}
                                type="password"
                                name="newpassword"
                                value={inputValues.newpassword}
                                id=""
                                aria-describedby="emailHelp"
                                placeholder="Enter New Password"
                                onChange={inputEvent}
                            />
                            <small className="error_msg">{newpassworderror}</small>
                        </div>
                        <div className="form-group form_grp mb-10">
                            {/* <label for="exampleInputPassword1">Password <span >*</span></label> */}
                            <div className={`form-group form_grp  ${confirmpassworderror ? 'error' : ''}`}>
                                <input
                                    type={type}
                                    name="confirmpassword"
                                    placeholder="Enter Confirm Password"
                                    value={inputValues.confirmpassword}
                                    onChange={inputEvent}                                  
                                    className={`form-control form_ctrl ${confirmpassworderror ? 'error' : ''}`}
                                />
                                <small className="error_msg">{confirmpassworderror}</small>
                            </div>
                            <span className="passEye" onClick={handleToggle}>
                                {/* <Image src={eyeicon} alt="password eye" /> */}
                                <Icon className="absolute mr-10" icon={icon} size={25} />
                            </span>
                        </div>
                        {/* <div className='backto_login forget_pass'>
                            <NavLink to="/forgotpassword" className="forget_passBtn">Forgot Password</NavLink>
                        </div> */}

                        <button type="submit" onClick={handleSubmit} className="btn btn-primary loginButtons popFBtn  wd-100">Submit</button>

                        {/* {isFormValid ? (
                            <button type="submit" className="btn btn-primary loginButtons popFBtn  wd-100">
                                Submit
                            </button>
                        ) : null} */}
                    </form>
                </div>
            </div>
        </>
    )
}
export default Changepassword;