import React, { useState, useEffect } from 'react';
import './ConfirmPassword.css'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import brainLogo from '../../assets/mobnav.png';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import newLogo from "../../assets/dash_updated_logo.png";

const ConfirmPassword = () => {
    const navigate = useNavigate();
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [authenticationToken, setAuthenticationToken] = useState("");
    //states for show and hide password...   
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [inputValues, setInputValues] = useState({
        password: "",
        confirmPassword: "",
    });

    useEffect(() => {
        // Get the URL search string (e.g., "?token=...")
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
        console.log("Token from URL:", token);
        let value = {
            mbts_verification_token: token
        }
        Axios.post("/admin/verifyEmail", value)
            .then((response) => {
                console.log("verify email response response", response);
                if (response.data.code == 200) {
                    // setAuthenticationToken(response.data.data);
                    localStorage.setItem('authenticationToken', response.data.data);
                }
                else if (response.data.code == 500) {
                    navigate("/error");
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    const inputEvent = (e) => {
        var { name, value } = e.target;
        setInputValues((previousValue) => ({
            ...previousValue,
            [name]: value,
        }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("122==", inputValues);
        setConfirmPasswordError("");
        if (inputValues.password.trim() === "") {
            setPasswordError("Password is required");
            return false;
        } else if (inputValues.confirmPassword.trim() === "") {
            setConfirmPasswordError("Confirm Password is required");
            return false;
        } else if (!/^^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(inputValues.password)) {
            setPasswordError("Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.");
            return false;
        } else if (inputValues.password !== inputValues.confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
            return false;
        } else {
            let value = {
                password: inputValues.password,
                userId: "652635d6ca2b3392093182ef"
            }

            const authToken = localStorage.getItem('authenticationToken'); // Retrieve the token from local storage

            // Create an object that includes the token in the 'Authorization' header
            const config = {
                headers: {
                    'Authorization': `Bearer ${authToken}` // Assuming it's a Bearer token
                }
            };
            Axios.post("/admin/resetPassword", value, config)
                .then((response) => {

                    localStorage.setItem('token', JSON.stringify(response.data.data.token))

                    if (response.data.code === 500) {
                        const message = response.data.data;
                        toast.error(message, {
                            position: 'top-right',
                        });
                    }
                    else if (response.data.code === 200) {
                        const message = response.data.data;
                        toast.success(message, {
                            position: 'top-right',
                        });
                    }
                    navigate("/");
                    console.log("login response", response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <>
            <div className="container-fluid adminLogin fade_wrapper">
                <div className='login_wrapper box_wrapper'>
                    <div className='form_logo'>
                        {/* <img src={brainLogo} alt="logo" /> */}
                        <img src={newLogo} alt="logo" />

                    </div>
                    <form>
                        <div className="form-group form_grp mb-10">
                            {/* <label for="exampleInputPassword1">Password <span >*</span></label> */}
                            <input
                                type={type}
                                name="password"
                                placeholder="Password"
                                value={inputValues.password}
                                onChange={inputEvent}
                                className="form_ctrl pass_ctrl error"
                            />
                            {/* <span className="passEye" onClick={handleToggle}>                                
                                <Icon className="absolute mr-10" icon={icon} size={25} />
                            </span> */}
                            <small className='error_msg'>{passworderror}</small>
                        </div>
                        <div className="form-group form_grp mb-10">
                            {/* <label for="exampleInputPassword1">Password <span >*</span></label> */}
                            <input
                                type={type}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={inputValues.confirmPassword}
                                onChange={inputEvent}
                                className="form_ctrl pass_ctrl error"
                            />
                            {/* <span className="passEye" onClick={handleToggle}>                                
                                <Icon className="absolute mr-10" icon={icon} size={25} />Submitss
                            </span> */}
                            <small className='error_msg'>{confirmPasswordError}</small>
                        </div>

                        <button type="submit" onClick={handleSubmit} className="btn btn-primary loginButtons popFBtn  wd-100">Submit</button>
                    </form>
                </div>
            </div>
        </>
    )
}
export default ConfirmPassword;