import React, { useState, useEffect } from 'react';
import './login.css'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import brainLogo from '../../assets/mobnav.png';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hasToken } from '../../utils/tokenUtil';
import newLogo from "../../assets/dash_updated_logo.png";

const Login = () => {
    const navigate = useNavigate();
    const [emailerror, setEmailError] = useState("");
    const [passworderror, setPasswordError] = useState("");
    //states for show and hide password...
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [inputValues, setInputValues] = useState({
        email: "",
        password: "",
    });

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // A simple email pattern
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_])[A-Za-z\d@#$%^&+=!_]{8,20}$/;
    useEffect(() => {
        // Define an async function to fetch data from your API
        async function fetchData() {
            try {
                const response = await Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/cheakAdminExistance`);
                
                if (response.data.code == 500) {
                    navigate('/registration');
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                // setLoading(false);
            }
        }

        fetchData(); // Call the fetchData function when the component mounts
    }, []);

    const inputEvent = (e) => {
        console.log(inputValues);
        var { name, value } = e.target;
        setInputValues((previousValue) => {

            if (name == "email") {
                return {
                    email: value,
                    password: previousValue.password
                }
            } else if (name == "password") {
                return {
                    password: value,
                    email: previousValue.email
                }
            }
        });
    }
    //function to show and hide password
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
       
        setEmailError("");
        setPasswordError("");

        if (inputValues.email === "") {
            setEmailError("Please enter a valid email address");
            return false;
        }
        else if (!emailRegex.test(inputValues.email)) {
            setEmailError("Invalid email format");
            return false;
        }
        if (inputValues.password === "") {
            setPasswordError("Password is required");
            return false;
        }
        // else if (!passwordRegex.test(inputValues.password)) {
        //     setPasswordError("Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.");
        //     return false;
        // } 
        else {
            let loginValue = {
                mbts_email: inputValues.email,
                mbts_password: inputValues.password
            }
            console.log(loginValue, "13")
            Axios.post("/admin/adminLogin", loginValue)
                .then((response) => {
                    console.log("61", response)                  

                    if (response.data.code === 500) {
                        const message = response.data.data;
                        toast.error(message, {
                            position: 'top-right',
                        });
                    }
                    else if (response.data.code === 200) {
                        localStorage.setItem('token', response.data.data.token)
                        const message = response.data.data;
                        toast.success(message, {
                            position: 'top-right',
                        });
                        navigate('/dashboard')
                    }
                    
                    console.log("login response", response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <>
            <div className="container-fluid adminLogin fade_wrapper">
                <div className='login_wrapper box_wrapper'>
                    <div className='form_logo'>
                        {/* <img src={brainLogo} alt="logo" /> */}
                        <img src={newLogo} alt="logo" />
                    </div>
                    <form>
                        <div className={`form-group form_grp ${emailerror ? 'error' : ''}`}>
                            <input
                                type="email"
                                className={`form-control form_ctrl ${emailerror ? 'error' : ''}`}
                                name="email"
                                value={inputValues.email}
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                onChange={inputEvent}
                            />
                            <small className='error_msg'>{emailerror}</small>
                        </div>
                        <div className="form-group form_grp mb-10">
                            {/* <label for="exampleInputPassword1">Password <span >*</span></label> */}
                            <div className={`form-group form_grp ${passworderror ? 'error' : ''}`}>
                            <input
                                type={type}
                                name="password"
                                placeholder="Password"
                                value={inputValues.password}
                                onChange={inputEvent}
                                className={`form-control form_ctrl ${passworderror ? 'error' : ''}`}
                            />
                              <small className='error_msg'>{passworderror}</small>
                        </div>
                            <span className="passEye" onClick={handleToggle}>
                                {/* <Image src={eyeicon} alt="password eye" /> */}
                                <Icon className="absolute mr-10" icon={icon} size={25} />
                            </span>                            
                        </div>
                        <div className='backto_login forget_pass'>
                            <NavLink to="/forgotpassword" className="forget_passBtn">Forgot Password</NavLink>
                        </div>

                        <button type="submit" onClick={handleSubmit} className="btn btn-primary loginButtons popFBtn  wd-100">Login</button>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Login;