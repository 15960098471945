
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import close from "../../assets/close.svg";
import linkSent from "../../assets/link_sent.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Axios from 'axios'


const LinkSent = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleResend = async () => {
    let forgetValue = {
      email: props.email,
  }
    Axios.post("/admin/forgetPassword", forgetValue)
      .then((response) => {
        // localStorage.setItem('token', JSON.stringify(response.data.data.token));
       
        if (response.data.code == 200) {
         // setModalOpen(true)
         toast.success("Resend Succcessfully", {
          position: 'top-right',
        });
        }
        else if (response.data.code == 500) {
          const message = response.data.data;
          toast.error(message, {
            position: 'top-right',
          });
        }
        console.log("login response", response)
      })
      .catch((error) => {
        console.log(error);
      })
  }
  return (
    <div>
      {/* <Button
        className="popFBtn  wd-100"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Request Reset Link
      </Button> */}
      {/* <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen} className='mbtsModCont'>
        <ModalBody> */}
      {/* button to close popup */}
      <Link to="/">
        <Button
          className="popCloseBtn"
          type="button"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <img src={close} alt="close button" />
        </Button>
      </Link>
      <div className="popIconWrp">
        <img src={linkSent} alt="link sent icon" />
      </div>
      <h2 className="pop_hd">Link Sent</h2>
      <p className="pop_text">
        Check your email. We have sent the password reset link.
      </p>

      <div className="popFormBtn">
        <Link exact to="/" className="popFBtn">Back to Login</Link>
      </div>
      <div className="alreadyAcc link_resend">
        <p>Didn&apos;t receive? <Link onClick={handleResend} >Resend</Link>
        </p>
      </div>
      {/* </ModalBody>
      </Modal> */}
    </div>
  );
};

export default LinkSent;
