// import React, { Component, } from 'react'
// import './Sidebar.css'
// import $ from 'jquery'
// import { NavLink } from 'react-router-dom'
// import {FaQuestionCircle, FaWallet } from "react-icons/fa";
// import { MdCrisisAlert } from "react-icons/md";
// import { MdAccountTree } from "react-icons/md";
// import { TiMessages } from "react-icons/ti";

// export default class Sidebar extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {

//         }
//     }
//     componentDidMount() {
//         $(document).ready(function () {
//             $('.showMobMenu').click(function () {
//                 $('.sidebarOuter').toggleClass('mobmenu');
//                 $('.dashboardOuter .rightSection').toggleClass('rightslide');
//             });
//         });
//     }
//     logOut = () => {
//         console.log("222222222222")
//         localStorage.removeItem('token')
//         window.location.href = "/"
//     }
//     render() {
//         return (
//             <>
//                 <div className="sidebarOuter">
//                     <div className="sidebarScroll">
//                         <div className="">
//                             <ul>
//                                 <li>
//                                     <NavLink to="/dashboard">
//                                         <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>
//                                         <span>Dashboard</span>
//                                     </NavLink>
//                                 </li>


//                                 <li>
//                                     <NavLink to="/dashboard/usermanagement"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
//                                         width="1em" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path></svg>
//                                         <span>User Management</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/accountability-coach">
//                                         <i><MdAccountTree /></i>
//                                         <span>Accountability Coach</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/questions">
//                                         <i><FaQuestionCircle /></i>
//                                         <span>Question Management</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/subscription">
//                                         <i><MdCrisisAlert /></i>
//                                         <span>Subscriptions Plans</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/payments">
//                                         <i><FaWallet /></i>
//                                         <span>Payment Status</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/inquiry">
//                                         <i><TiMessages /></i>
//                                         <span>Enquiry</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/usermanagement"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
//                                         width="1em" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path></svg>
//                                         <span>User Management</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/accountability-coach">
//                                         <i><MdAccountTree /></i>
//                                         <span>Accountability Coach</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/questions">
//                                         <i><FaQuestionCircle /></i>
//                                         <span>Question Management</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/subscription">
//                                         <i><MdCrisisAlert /></i>
//                                         <span>Subscriptions Plans</span>
//                                     </NavLink>
//                                 </li>
//                                 <li>
//                                     <NavLink to="/dashboard/payments">
//                                         <i><FaWallet /></i>
//                                         <span>Payment Status</span>
//                                     </NavLink>
//                                 </li>

//                                 {/* <li>
//                                     <NavLink to="verifyVC"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 3c-1.2 0-2.4.6-3 1.7A3.6 3.6 0 0 0 4.6 9c-1 .6-1.7 1.8-1.7 3s.7 2.4 1.7 3c-.3 1.2 0 2.5 1 3.4.8.8 2.1 1.2 3.3 1 .6 1 1.8 1.6 3 1.6s2.4-.6 3-1.7c1.2.3 2.5 0 3.4-1 .8-.8 1.2-2 1-3.3 1-.6 1.6-1.8 1.6-3s-.6-2.4-1.7-3c.3-1.2 0-2.5-1-3.4a3.7 3.7 0 0 0-3.3-1c-.6-1-1.8-1.6-3-1.6Z"></path><path d="m9 12 2 2 4-4"></path></svg> <span>Verify Vc</span></NavLink>
//                                 </li> */}
//                                 <li>
//                                     <a href="#">
//                                         <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"></path></g></svg>

//                                         <span onClick={this.logOut.bind(this)}>Logout</span></a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </>
//         )
//     }


// }


import React, { useEffect } from 'react';
import './Sidebar.css';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import { FaQuestionCircle, FaWallet } from 'react-icons/fa';
import { MdCrisisAlert, MdAccountTree } from 'react-icons/md';
import { TiMessages } from "react-icons/ti";

const Sidebar = () => {
  useEffect(() => {
    $(document).ready(function () {
      $('.showMobMenu').click(function () {
        $('.sidebarOuter').toggleClass('mobmenu');
        $('.dashboardOuter .rightSection').toggleClass('rightslide');
      });
    });
  }, []);

  const logOut = () => {
    console.log('222222222222');
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  return (
    <>
      <div className="sidebarOuter">
        <div className="sidebarScroll">
          <div className="">
            <ul>
              <li>
                <NavLink to="/dashboard">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 576 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path>
                  </svg>
                  <span>Dashboard</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/dashboard/usermanagement">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path>
                  </svg> 
                  <span>User Management</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/accountability-coach">
                  <i>
                    <MdAccountTree />
                  </i>
                  <span>Accountability Coach</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/questions">
                  <i>
                    <FaQuestionCircle />
                  </i>
                  <span>Question Management</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/subscription">
                  <i>
                    <MdCrisisAlert />
                  </i>
                  <span>Subscriptions Plans</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/payments">
                  <i>
                    <FaWallet />
                  </i>
                  <span>Payment Status</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/inquiry">
                    <i><TiMessages /></i>
                    <span>Inquiries</span>
                    </NavLink>
                </li>
                <li className='asst'>
                <NavLink to="/dashboard/my-assets">
                <svg width="64px" height="64px" viewBox="0 0 48 48" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                 
                  <path class="cls-1" d="M41.68,13H24.77c-2-.1-5.93-4.23-8.19-4.23H6.68A2.18,2.18,0,0,0,4.5,11h0v7.29h39V14.87A1.83,1.83,0,0,0,41.68,13Z"></path><path class="cls-1" d="M43.5,18.28H4.5V37A2.18,2.18,0,0,0,6.67,39.2H41.32A2.18,2.18,0,0,0,43.5,37h0Z"></path></g></svg>

                  <span>Assets Management</span>
                </NavLink>
              </li>
              <li>
                <a href="#">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"></path>
                    </g>
                  </svg>

                  <span onClick={logOut}>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar;

