import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, Input, FormGroup, Label } from 'reactstrap';
import classnames from 'classnames';
import { MdClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalcIcon from '../../src/assets/appointment-icon.png'

const Availabelity = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [startDate, setStartDate] = useState(new Date());
  const [collapse, setCollapse] = useState(false);

  const toggleCol = () => {
    setCollapse(!collapse);
  };

  const [isVisible, setIsVisible] = useState(true);

  const handleButtonClick = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <Input
        type="Name"
        className="form_ctrl form_sel"
        name="firstName"     
        id="exampleEmail"
        placeholder="Cost"
      />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}
          >
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio1" />
                Recurring Time Slots
              </Label>
            </FormGroup>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggle('2')}
          >
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio1" />
                Custom Time Slots
              </Label>
            </FormGroup>
          </NavLink>
        </NavItem>
      </Nav>
      <div className='slotTimezoneBx'>
        <div className='slotTime_Left'>
          <div className="form_grp">
            <Input
              type="select"
              name="mbts_degree"
              className="form_ctrl form_sel"
              id="exampleSelect"
            >
              <option>(UTC-08:00) Pacific Time (US &amp; Canada)</option>
            </Input>
          </div>
        </div>
        <div className='slotTime_Right'>
          <span>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio2" />
                12 Hours
              </Label>
            </FormGroup>
          </span>
          <span>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio2" />
                24 Hours
              </Label>
            </FormGroup>
          </span>
        </div>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">

          <div className='slotsListOuter'>
            <div className='slotBxInn'>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Sunday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Collapse className="addSlotToggle" isOpen={collapse}>
                    <div className='slotFrmBx'>
                      <Button className="clearBtn" onClick={toggleCol}>Cancel</Button>
                      <div className='slottimeBx'>
                        <Input type="time" name="time" className="time_ctrl" />
                      </div>
                      <div className='slottimeBx'>
                        <Input type="time" name="time" className="time_ctrl" />
                      </div>
                    </div>
                  </Collapse>
                  <Button className="TimeslotBtn" onClick={toggleCol} >Add Timeslots</Button>
                </div>
                <div className='slotList'>
                  <ul>
                    {isVisible && (
                      <li>
                        <span>10:00 AM -</span>
                        <span>11:00 PM</span>
                        <Button><MdClose /></Button>
                      </li>
                    )}
                    {isVisible && (
                      <li>
                        <span>10:00 AM -</span>
                        <span>11:00 PM</span>
                        <Button><MdClose /></Button>
                      </li>
                    )}
                    {isVisible && (
                      <li>
                        <span>10:00 AM -</span>
                        <span>11:00 PM</span>
                        <Button><MdClose /></Button>
                      </li>
                    )}
                    {isVisible && (
                      <li>
                        <span>10:00 AM -</span>
                        <span>11:00 PM</span>
                        <Button><MdClose /></Button>
                      </li>
                    )}
                    {isVisible && (
                      <li>
                        <span>10:00 AM -</span>
                        <span>11:00 PM</span>
                        <Button><MdClose /></Button>
                      </li>
                    )}
                  </ul>
                </div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>12</span>
                </div>
              </div>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Monday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Button className="TimeslotBtn">Add Timeslots</Button>
                </div>
                <div className='slotList'>
                  <ul>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                  </ul>
                </div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>11</span>
                </div>
              </div>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Tuesday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Button className="TimeslotBtn">Add Timeslots</Button>
                </div>
                <div className='slotList'>
                  <ul>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                  </ul>
                </div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>9</span>
                </div>
              </div>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Wednesday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Button className="TimeslotBtn">Add Timeslots</Button>
                </div>
                <div className='slotList'>
                  <ul>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                  </ul>
                </div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>10</span>
                </div>
              </div>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Thursday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Button className="TimeslotBtn">Add Timeslots</Button>
                </div>
                <div className='slotList'>
                  <ul>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                    <li>
                      <span>10:00 AM -</span>
                      <span>11:00 PM</span>
                      <Button><MdClose /></Button>
                    </li>
                  </ul>
                </div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>8</span>
                </div>
              </div>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Friday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Button className="TimeslotBtn">Add Timeslots</Button>
                </div>
                <div className='slotList'></div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>0</span>
                </div>
              </div>
              <div className='dayNameOuter'>
                <div className='dayNameHd'>Saturday</div>
                <div className='addslote'>
                  <Button className="clearBtn">Clear</Button>
                  <Button className="TimeslotBtn">Add Timeslots</Button>
                </div>
                <div className='slotList'></div>
                <div className='bookedslotsNumb'>
                  Time Slots
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className='customSloteOuter'>
            <div className='customslotBx'>
              <div className='customdateBx'>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                <i><img src={CalcIcon} alt="" /></i>
              </div>
              <div className='customdateBx'>
                <Input type="time" name="time" className="customDate" />
              </div>
              <div className='customdateBx'>
                <Input type="time" name="time" className="customDate" />
              </div>
              <div className='addDelBtnBx'>
                <Button className="addBtn">Add</Button>
              </div>
            </div>
            <div className='bookedslotList'>
              <h3>Booked slot date: 02/12/2023</h3>
              {isVisible && (
                <span>10:00 AM - 11:AM
                  <Button className="slotdel"><MdClose /></Button>
                </span>
              )}
              {isVisible && (
                <span>10:00 AM - 11:AM
                  <Button onClick={handleButtonClick} className="slotdel"><MdClose /></Button>
                </span>
              )}
            </div>
            <div className='bookedslotList'>
              <h3>Booked slot date: 01/12/2023</h3>
              {isVisible && (
                <span>10:00 AM - 11:AM
                  <Button className="slotdel"><MdClose /></Button>
                </span>
              )}
              {isVisible && (
                <span>10:00 AM - 11:AM
                  <Button onClick={handleButtonClick} className="slotdel"><MdClose /></Button>
                </span>
              )}
            </div>
          </div>
          {/* <div className='addfor'>
            <a href=''>Add For Other Dates</a>
          </div> */}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Availabelity;
