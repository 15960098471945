import React from 'react'
import Forgotpassword from '../Components/Forgotpassword/Forgotpassword';


const ChangepasswordRoutes = () =>{
    
    return(
        <div className=''>
      
        <Forgotpassword />
       
        
        </div>
    )
}
export default ChangepasswordRoutes;