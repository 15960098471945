import React, { useState, useEffect } from "react";
import { Tooltip } from "reactstrap";
import "./myAssets.css";
import { IoIosInformationCircleOutline } from "react-icons/io";
import combinedServices from "../services/service";
const MyAssets = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [assets, setAssets] = useState([]);
  const [dataObject, setDataObject] = useState({
    homePageVideo: "",
    preparationVideo: "",
    imaginationVideo: "",
    internalizationVideo: "",
    orchestrationVideo: "",
    affirmation: "",
  });

  const getVideoLink = async () => {
    let response = await combinedServices.getVideoLink();
    console.log("api response :", response.data);
    try {
      let response_data = response;
      setAssets(response_data.data.data);
      setDataObject({
        homePageVideo: response_data.data.data[0].homePageVideo,
        preparationVideo: response_data.data.data[0].preparationVideo,
        imaginationVideo: response_data.data.data[0].imaginationVideo,
        internalizationVideo: response_data.data.data[0].internalizationVideo,
        orchestrationVideo: response_data.data.data[0].orchestrationVideo,
        affirmation: response_data.data.data[0].affirmation,
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    getVideoLink();
  }, []);

  const updateVideoLink = async (data) => {
    const token = localStorage.getItem("token");
    let response = await combinedServices.updateVideoLink(data, token);
    console.log("api response :", response.data);
    try {
      let response_data = response;
      setAssets(response_data.data.data);
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const handleUpdate = (e, id) => {
    e.preventDefault();
    let data = {
      _id: id,
      homePageVideo: dataObject.homePageVideo,
      preparationVideo: dataObject.preparationVideo,
      imaginationVideo: dataObject.imaginationVideo,
      internalizationVideo: dataObject.internalizationVideo,
      orchestrationVideo: dataObject.orchestrationVideo,
      affirmation: dataObject.affirmation,
    };
    console.log("data", data);
    // return;
    updateVideoLink(data);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setDataObject({
      ...dataObject,
      [name]: value,
    });
  };

  return (
    <>
      <div className="middleSection-wrapper">
        <div className="dashboardOuter">
          <div className="rightSection">
            <div className="dashcardOuter">
              <div class="subsHdBx">
                <h1>Assets</h1>
              </div>
              <div className="asstWrp">
                <div className="asstSection">
                  <div className="asstInner">
                    <span>
                      home page video Url/ Embeded code
                      <span href="#" id="TooltipExample" className="ttlAsst">
                        <IoIosInformationCircleOutline />
                      </span>
                      <Tooltip
                        placement="right"
                        isOpen={tooltipOpen}
                        target="TooltipExample"
                        toggle={toggle}
                        className="ttlTxt"
                      >
                        In case of Youtube Video, Please try to paste Embeded
                        Code.
                      </Tooltip>
                    </span>
                    <div className="asst_grp">
                      <input
                        type="text"
                        className="frm_ctrl form-control searchBar"
                        name="homePageVideo"
                        value={dataObject.homePageVideo}
                        onChange={handleChanges}
                      />
                      <button onClick={(e) => handleUpdate(e, assets[0]?._id)}>
                        update
                      </button>
                    </div>
                  </div>
                  <div className="asstInner">
                    <h2 className="subhd">journey guided video</h2>
                    <span>Preparation video Url/ Embeded code</span>
                    <div className="asst_grp">
                      <input
                        type="text"
                        className="frm_ctrl form-control searchBar"
                        name="preparationVideo"
                        value={dataObject.preparationVideo}
                        onChange={handleChanges}
                      />
                      <button onClick={(e) => handleUpdate(e, assets[0]?._id)}>
                        update
                      </button>
                    </div>
                  </div>
                  <div className="asstInner">
                    <span>Imagination video Url/ Embeded code</span>
                    <div className="asst_grp">
                      <input
                        type="text"
                        className="frm_ctrl form-control searchBar"
                        name="imaginationVideo"
                        value={dataObject.imaginationVideo}
                        onChange={handleChanges}
                      />
                      <button onClick={(e) => handleUpdate(e, assets[0]?._id)}>
                        update
                      </button>
                    </div>
                  </div>
                  <div className="asstInner">
                    <span>Internalization video Url/ Embeded code</span>
                    <div className="asst_grp">
                      <input
                        type="text"
                        className="frm_ctrl form-control searchBar"
                        name="internalizationVideo"
                        value={dataObject.internalizationVideo}
                        onChange={handleChanges}
                      />
                      <button onClick={(e) => handleUpdate(e, assets[0]?._id)}>
                        update
                      </button>
                    </div>
                  </div>
                  <div className="asstInner">
                    <span>Orchestration video Url/ Embeded code</span>
                    <div className="asst_grp">
                      <input
                        type="text"
                        className="frm_ctrl form-control searchBar"
                        name="orchestrationVideo"
                        value={dataObject.orchestrationVideo}
                        onChange={handleChanges}
                      />
                      <button onClick={(e) => handleUpdate(e, assets[0]?._id)}>
                        update
                      </button>
                    </div>
                  </div>
                  <div className="asstInner">
                    <span>Affirmation video Url/ Embeded code</span>
                    <div className="asst_grp">
                      <input
                        type="text"
                        className="frm_ctrl form-control searchBar"
                        name="affirmation"
                        value={dataObject.affirmation}
                        onChange={handleChanges}
                      />
                      <button onClick={(e) => handleUpdate(e, assets[0]?._id)}>
                        update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAssets;
