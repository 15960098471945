// import React, { Component } from 'react';
// import AdminLogin from './Components/Login/login'
// import Resetpassword from './Pages/ChangepasswordRoutes'
// import Dashboard from './Pages/dashboard';
// import Forgotpassword from './Pages/ForgotpasswordRoutes'
// // import GenerateVC from '../../Pages/GenerateVC'
// import Transcript from './Pages/NewStudent'
// import Questions from './Pages/Questions'
// import EditProfile from './Pages/EditProfile'
// import AccountabilityCoach from './Pages/AccountabilityCoach'
// import EditCoachProfile from './Pages/EditCoachProfile'
// import Subscription from './Pages/Subscription'
// import AddSubscription from './Pages/AddSubscription'
// import Payments from './Pages/Payments'
// import Inquiry from './Pages/Enquiry'
// // import VerifyVC from '../../Pages/VerifyVC'
// //import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import AdminRegister from './Components/Regitration/register';
// import SuccessRegister from './Components/Regitration/successregister';
// import LinkSent from './Components/Forgotpassword/linksent';
// import ConfirmPassword from './Components/ConfirmPassword/ConfirmPassword';
// import Error from './Components/Error';
// import SuccessActivationUser from './Components/SuccessActivationUser/SuccessActivationUer';


// // PrivateRoute component for protecting routes
// const PrivateRoute = ({ element, ...props }) => {
//   const token = localStorage.getItem('token');

//   return (
//     <Route
//       {...props}
//       element={token ? element : <Navigate to="/" />}
//     />
//   );
// };

// export default class Layout extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: localStorage.getItem('token') || '',
//     };
//   }

//   componentDidMount() {
//     let token = localStorage.getItem('token');
//     this.setState({ token: token });
//   }


//   render() {
//     const { token } = this.state;
//     console.log("55======",token);
//     return (
//       <div>
//         <Routes>
//           {/* <Route
//             path="/*" // This will match any route
//             element={token ? <Navigate to="/" /> : null}
//           /> */} 

//           <Route
//             path="/*"
//             element={token? <Navigate to="/" /> : null}
//           />

//           <Route exact path="/" element={<AdminLogin />} />

//           <Route exact path='/forgotpassword' element={<Forgotpassword />} />
//           <Route exact path='/registration' element={<AdminRegister />} />
//           <Route exact path='/verifyEmail' element={<ConfirmPassword />} />
//           <Route exact path="/dashboard" element={<Dashboard />} />
//           <Route exact path='/changepassword' element={<Resetpassword />} />
//           <Route exact path='/linksent' element={<LinkSent />} />
//           <Route exact path='/successregister' element={<SuccessRegister />} />
//           <Route exact path="/sucess-activation" element={<SuccessActivationUser />} />
//           <Route exact path='/error' element={<Error />} />       
//           {/* Token check for the following routes */}
//           {token && (
//             <>             
//               <Route exact path="/dashboard/usermanagement" element={<Transcript />} />
//               <Route exact path="/dashboard/accountability-coach" element={<AccountabilityCoach />} />
//               <Route exact path="/dashboard/edit-coach-profile/:userId?" element={<EditCoachProfile />} />
//               <Route exact path="/dashboard/questions" element={<Questions />} />
//               <Route exact path='/dashboard/edit-profile/:userId?' element={<EditProfile />} />
//               <Route exact path='/dashboard/subscription' element={<Subscription />} />
//               <Route exact path='/dashboard/add-subscription/:id?' element={<AddSubscription />} />
//               <Route exact path='/dashboard/payments' element={<Payments />} />
//               <Route exact path='/dashboard/inquiry' element={<Inquiry />} />
//             </>
//             )}  

//           {/* <Redirect from="/" to="/dashboard" /> */}
//         </Routes>
//       </div>
//     )
//   }

// }

import React, { Component } from 'react';
import AdminLogin from './Components/Login/login'
import Resetpassword from './Pages/ChangepasswordRoutes'
import Dashboard from './Pages/dashboard';
import Forgotpassword from './Pages/ForgotpasswordRoutes'
// import GenerateVC from '../../Pages/GenerateVC'
import Transcript from './Pages/NewStudent'
import Questions from './Pages/Questions'
import EditProfile from './Pages/EditProfile'
import AccountabilityCoach from './Pages/AccountabilityCoach'
import EditCoachProfile from './Pages/EditCoachProfile'
import Subscription from './Pages/Subscription'
import AddSubscription from './Pages/AddSubscription'
// import VerifyVC from '../../Pages/VerifyVC'
//import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminRegister from './Components/Regitration/register';
import SuccessRegister from './Components/Regitration/successregister';
import LinkSent from './Components/Forgotpassword/linksent';
import ConfirmPassword from './Components/ConfirmPassword/ConfirmPassword';
import Error from './Components/Error';
import SuccessActivationUser from './Components/SuccessActivationUser/SuccessActivationUer';
 import Payments from './Pages/Payments';
import Inquiry from './Pages/Enquiry';
import MyAssets from './Pages/MyAssets';




export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
    }
  }

  componentDidMount() {
    let token = localStorage.getItem('token');
    this.setState({ token: token });
  }


  render() {
    const { token } = this.state;
    return (
      <div>
        <Routes>
          <Route
            path="/*" // This will match any route
            element={!token ? <Navigate to="/" /> : null}
          />
          <Route exact path="/" element={<AdminLogin />} />

          <Route exact path='/forgotpassword' element={<Forgotpassword />} />
          <Route exact path='/registration' element={<AdminRegister />} />
          <Route exact path='/verifyEmail' element={<ConfirmPassword />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path='/changepassword' element={<Resetpassword />} />
          <Route exact path='/linksent' element={<LinkSent />} />
          <Route exact path='/successregister' element={<SuccessRegister />} />
          <Route exact path="/sucess-activation" element={<SuccessActivationUser />} />
          <Route exact path='/error' element={<Error />} />
          {/* Token check for the following routes */}
          {/* {token && ( */}
            <>                      
              <Route exact path="/dashboard/usermanagement" element={<Transcript />} />
              <Route exact path="/dashboard/accountability-coach" element={<AccountabilityCoach />} />
              <Route exact path="/dashboard/my-assets" element={<MyAssets />} />
              <Route exact path="/dashboard/edit-coach-profile/:userId" element={<EditCoachProfile />} />
              <Route exact path="/dashboard/questions" element={<Questions />} />
              <Route exact path='/dashboard/edit-profile/:userId' element={<EditProfile />} />
              <Route exact path='/dashboard/subscription' element={<Subscription />} />
              <Route exact path='/dashboard/add-subscription/:id?' element={<AddSubscription />} />
              <Route exact path='/dashboard/payments' element={<Payments />} />
               <Route exact path='/dashboard/inquiry' element={<Inquiry />} />
            </>
          {/* ) } */}
          {/* <Redirect from="/" to="/dashboard" /> */}
        </Routes>
      </div>
    )
  }

}